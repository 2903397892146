import { Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import ErrorMessage from 'components/error/ErrorMessage';
import SuccessMessage from 'components/form/SuccessMessage';
import HFTextField from 'components/hookForm/HFTextField';
import HFFormProvider from 'ctx/HFFormProvider';
import { useTranslation } from 'react-i18next';
import { getTranslatedOrFallback } from 'utils/translation';
import { useCallback, useEffect, useState } from 'react';
import useThrottleStartStopFn from 'hooks/useThrottleStartStopFns';
import { PasswordPolicy } from 'models/MyCapitechClientSettings';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import useExternalLoginProvider from 'hooks/useExternalLoginProvider';
import usePasswordChangeForm from './hooks/usePasswordChangeForm';
import PasswordPolicyProgress from './PasswordPolicyProgress';

type PasswordChangeFormProps = {
    passwordPolicy: PasswordPolicy;
};

export default function PasswordChangeForm({ passwordPolicy }: PasswordChangeFormProps) {
    const { t } = useTranslation();
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isFocusingNewPassword, setIsFocusingNewPassword] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const { provider } = useExternalLoginProvider();

    const formProps = usePasswordChangeForm({
        onBeforeHandleSubmit: () => {
            setDisplaySuccess(false);
            return true;
        },
        passwordPolicy
    });

    const { isSubmitSuccessful } = formProps.formState;

    // Reset form and init display success message
    const { reset: resetForm } = formProps;
    useEffect(() => {
        if (isSubmitSuccessful) {
            resetForm();
            setDisplaySuccess(true);
        }
    }, [isSubmitSuccessful, resetForm]);

    // Clear success after 4 seconds, or if user submits again
    const { run, flush } = useThrottleStartStopFn({
        runAtStart: useCallback(() => setDisplaySuccess(true), []),
        runAtStop: useCallback(() => setDisplaySuccess(false), []),
        runTime: 4000
    });
    useEffect(() => {
        if (displaySuccess) run();
        else if (!isSubmitSuccessful) flush(); // The !isSubmitSuccessful avoids flush when displaySuccess is about to be set to true, which would revert it to false in the same tick.
    }, [isSubmitSuccessful, displaySuccess, run, flush]);

    let processedDisplayErrorMessage = formProps.displayErrorMessage;
    if (processedDisplayErrorMessage === 'Passordet er ikke korrekt') {
        processedDisplayErrorMessage = t('profile.passwordChange.oldPasswordIsIncorrect');
    }

    const currentNewPassword = formProps.watch('newPassword');

    const hasNewPasswordError = Boolean(formProps.formState.errors.newPassword);
    const showPasswordValidationProgress = Boolean(
        hasNewPasswordError || isFocusingNewPassword || currentNewPassword.length
    );
    const passwordVisibilityToggleLabel = t(
        isPasswordVisible
            ? 'profile.passwordChange.hidePassword'
            : 'profile.passwordChange.showPassword'
    );

    return (
        <HFFormProvider formProps={formProps}>
            <Grid container mt={2}>
                {provider === 'azure' && (
                    <Grid item xs={12} mb={2}>
                        <Typography variant="subtitle1">
                            {t('profile.passwordChange.azureLoginNotice')}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <Stack spacing={2} mb={2}>
                        <HFTextField
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="newPassword"
                            autoComplete="newPassword"
                            label={t('profile.passwordChange.newPassword')}
                            required
                            fullWidth
                            onFocus={() => setIsFocusingNewPassword(true)}
                            onBlur={() => setIsFocusingNewPassword(false)}
                            {...(showPasswordValidationProgress
                                ? {
                                      renderCustomValidationFeedback: (error) => (
                                          <PasswordPolicyProgress
                                              passwordPolicy={passwordPolicy}
                                              password={currentNewPassword}
                                              highlightUnmetPolicies={!!error}
                                          />
                                      )
                                  }
                                : {})}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            title={passwordVisibilityToggleLabel}
                                            aria-label={passwordVisibilityToggleLabel}
                                            onClick={() => setIsPasswordVisible((prev) => !prev)}
                                            edge="end"
                                        >
                                            {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        {processedDisplayErrorMessage && (
                            <ErrorMessage
                                message={getTranslatedOrFallback(processedDisplayErrorMessage)}
                            />
                        )}
                        {displaySuccess && (
                            <SuccessMessage>
                                {t('profile.passwordChange.successMessage') || ''}
                            </SuccessMessage>
                        )}
                    </Stack>

                    <ActionButton type="submit" isloading={formProps.formState.isSubmitting}>
                        {t('profile.passwordChange.passwordChange')}
                    </ActionButton>
                </Grid>
            </Grid>
        </HFFormProvider>
    );
}
