import AbsenceIcon from 'components/icon/AbsenceIcon';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import TransactionCard from './TransactionCard';
import { TransactionCardProps } from './TransactionCard.types';
import { DetailCardButton } from './buttons';

export default function AbsenceTransactionDetailCard({
    title,
    description,
    onRequestChangeClick,
    allowRequestChange
}: TransactionCardProps & {
    onRequestChangeClick: () => void;
    allowRequestChange: boolean;
}) {
    const { t } = useTranslation();
    return (
        <TransactionCard
            sx={{ bgcolor: 'absence.main' }}
            icon={<AbsenceIcon />}
            title={title}
            description={description}
            actions={
                allowRequestChange && (
                    <DetailCardButton
                        onClick={onRequestChangeClick}
                        label={t('actions.registerAbsenceChangeRequest')}
                        icon={<Edit />}
                    />
                )
            }
        />
    );
}
