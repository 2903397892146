import { GenericApiPayload } from 'hooks/useApiPost';
import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import useLoadingScreen from 'hooks/useLoadingScreen';
import { PropsWithChildren } from 'react';
import { FormProvider } from 'react-hook-form';

type HFFormProviderProps = PropsWithChildren<{
    formProps: UseFormWithApiIntegrationReturn<GenericApiPayload>;
    fullWidth?: boolean;
}>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function HFFormProvider({ children, formProps, fullWidth }: HFFormProviderProps) {
    // No use rendering a form when a loading screen is active. Just opens up possible issue with autoFocus not working.
    const { isVisible: isLoading } = useLoadingScreen();
    if (isLoading) return null;

    return (
        <FormProvider {...formProps}>
            <form
                style={{ width: fullWidth ? '100%' : undefined }}
                noValidate
                onSubmit={(event) => {
                    const beforeHandleSubmitResult = formProps.onBeforeHandleSubmit?.();
                    if (beforeHandleSubmitResult === false) {
                        event.preventDefault();
                        return false;
                    }

                    return formProps.handleSubmit(
                        formProps.onSubmitValidatedInputHandler,
                        formProps.onErrorHandler
                    )(event);
                }}
            >
                {children}
            </form>
        </FormProvider>
    );
}

HFFormProvider.defaultProps = {
    fullWidth: false
};
