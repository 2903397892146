import { Divider, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import useAppSettings from 'hooks/useAppSettings';
import { useCallback, useMemo, useState } from 'react';
import { useAuthorizedClientList } from 'hooks/useAuthorizedClientList';
import LogOutButton from './LogOutButton';
import MenuDrawer from './MenuDrawer';
import AuthorizedClientSelectionMenu from './AuthorizedClientSelectionMenu';

export default function ToggleAuth0MenuButton() {
    const isAuth0 = useAppSettings().loginSimployerAuth0;
    const { clientList, selectedClient } = useAuthorizedClientList();

    const isSingleClient = useMemo(() => clientList.length === 1, [clientList.length]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleOpenAuth0Menu = useCallback(() => {
        setIsMenuOpen((prev) => !prev);
    }, []);

    if (isSingleClient || !isAuth0) {
        return <LogOutButton />;
    }

    return (
        <>
            <MenuDrawer isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
                <List disablePadding>
                    <AuthorizedClientSelectionMenu />
                    <Divider />
                    <LogOutButton />
                </List>
            </MenuDrawer>
            <ListItem disableGutters disablePadding>
                <ListItemButton onClick={handleOpenAuth0Menu}>
                    <ListItemText
                        sx={{ maxWidth: '220px' }}
                        primary={selectedClient?.clientName || ''}
                        primaryTypographyProps={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            style: { whiteSpace: 'nowrap' }
                        }}
                    />
                </ListItemButton>
            </ListItem>
        </>
    );
}
