import { useFormContext, Controller } from 'react-hook-form';
import TextField, { TextFieldProps } from 'components/Input/TextField';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import { EnterKeyHint } from 'utils/input';
import React from 'react';

type HFTextFieldProps = {
    name: string;
    enterKeyHint?: EnterKeyHint;
    renderCustomValidationFeedback?: (error: string) => JSX.Element;
} & Omit<TextFieldProps, 'helperText'>;

function HFTextField({
    enterKeyHint,
    renderCustomValidationFeedback,
    ...textFieldProps
}: HFTextFieldProps) {
    const context = useFormContext();
    const {
        control,
        formState: { errors }
    } = context;

    const { name } = textFieldProps;
    useFieldNameGuard(name, context);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange, onBlur, ref } }) => (
                <TextField
                    {...textFieldProps}
                    inputRef={ref}
                    value={value ?? ''}
                    onChange={(ev) => {
                        onChange(ev);
                        if (typeof textFieldProps.onChange === 'function') {
                            textFieldProps.onChange(ev);
                        }
                    }}
                    onBlur={(...args) => {
                        textFieldProps.onBlur?.(...args);
                        return onBlur();
                    }}
                    {...{
                        inputProps: {
                            ...textFieldProps.inputProps,
                            ...(enterKeyHint ? { enterKeyHint } : null)
                        }
                    }}
                    error={!!errors[name]}
                    helperText={
                        !renderCustomValidationFeedback && errors[name]
                            ? (errors[name]?.message as unknown as string)
                            : ''
                    }
                    customHelperContent={
                        renderCustomValidationFeedback &&
                        renderCustomValidationFeedback(errors[name]?.message as unknown as string)
                    }
                />
            )}
        />
    );
}

HFTextField.defaultProps = {
    enterKeyHint: undefined,
    renderCustomValidationFeedback: undefined
};

export default HFTextField;
