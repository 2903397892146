import { MarkerTime } from 'utils/datetime';

export enum CanRequestAbsenceChangesStatus {
    CLOSED = 0,
    PARTIALLY_OPEN = 1,
    OPEN = 2
}

export interface Absence {
    absenceCode?: string;
    id: number;
    clientId?: number;
    employeeId: number;
    departmentId: number;
    orderId: number | null;
    projectId: number | null;
    freeDimension1Id: number | null;
    freeDimension2Id: number | null;
    notes?: string;
    source?: string;
    fromDate: string;
    toDate: string;
    fromTime?: MarkerTime | null;
    toTime?: MarkerTime | null;
    canRequestChangesStatus: CanRequestAbsenceChangesStatus;
}
