import { LocalizationProvider } from '@mui/x-date-pickers';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IS_DEV } from 'utils/constants';
import { getDateLocaleAvailableOrDefault, I18nDateAdapter, isDateLocaleAvailable } from 'appI18n';

export default function DateLocalizationProvider({ children }: PropsWithChildren) {
    const { i18n } = useTranslation();
    const preferredLanguage = i18n.language;
    const localeName = getDateLocaleAvailableOrDefault(preferredLanguage);

    useEffect(() => {
        if (!isDateLocaleAvailable(preferredLanguage) && IS_DEV) {
            // eslint-disable-next-line no-console
            console.warn(
                `Support for language ${preferredLanguage} needs to be set up in WorkDayPicker. Falling back to locale ${localeName}.`
            );
        }
    }, [preferredLanguage, localeName]);

    return (
        <LocalizationProvider adapterLocale={localeName} dateAdapter={I18nDateAdapter}>
            {children}
        </LocalizationProvider>
    );
}
