import { dateToIsoString } from 'utils/datetime';
import { useCallback, useMemo } from 'react';
import { useDialog } from 'ctx/DialogCtx';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import {
    AbsenceRequestDeleteDialog,
    AbsenceRequestRegistration,
    AbsenceChangeRequestRegistration,
    joinAbsenceRequestDescriptionsIntoString,
    useAbsenceRequestsAffectingDate
} from 'features/absence';
import IAbsenceTransactionResult from 'models/AbsenceTransactionResult';
import { CanRequestAbsenceChangesStatus } from 'models/Absence';
import { useSelectedDate } from '../ctx/SelectedTimePeriodCtx';
import useAbsenceTransactions from '../hooks/useAbsenceTransactions';
import { AbsenceRequestDetailCard, AbsenceTransactionDetailCard } from './cards';
import { useCapitechDataVisibility } from '../providers';

export default function AbsenceTransactionsDetails() {
    const selectedDate = useSelectedDate();
    const { absenceTransactions } = useAbsenceTransactions();
    const { absenceRequests, refreshAbsenceRequests } = useAbsenceRequestsAffectingDate(
        dateToIsoString(selectedDate)
    );
    const { refreshAbsenceTransactions } = useAbsenceTransactions();

    const { isVisibleData } = useCapitechDataVisibility();

    const visibleOpenRequests = useMemo(
        () =>
            isVisibleData('absenceRequest')
                ? absenceRequests.filter((req) => req.absenceRequestStatus === 'Created')
                : [],
        [absenceRequests, isVisibleData]
    );

    const { showDialog } = useDialog();

    const visibleAbsenceTransactionsForSelectedDate = useMemo(
        () =>
            isVisibleData('absence')
                ? absenceTransactions.filter((t) => t.dateFrom === dateToIsoString(selectedDate))
                : [],
        [absenceTransactions, isVisibleData, selectedDate]
    );

    const handleEditClick = useCallback(
        (id: number) => {
            showDialog((onClose) => {
                const absenceRequest = visibleOpenRequests.find((req) => req.id === id);
                if (!absenceRequest) throw new Error('Absence request not found');

                return (
                    <DialogWithCloseBtn onClose={onClose} open>
                        <AbsenceRequestRegistration
                            date={absenceRequest.fromDate}
                            absenceRequest={absenceRequest}
                            onCancel={onClose}
                            onSuccess={() => {
                                refreshAbsenceRequests();
                                onClose();
                            }}
                        />
                    </DialogWithCloseBtn>
                );
            });
        },
        [visibleOpenRequests, refreshAbsenceRequests, showDialog]
    );

    const handleDeleteClick = useCallback(
        (id: number) => {
            showDialog((onClose) => {
                const absenceRequest = visibleOpenRequests.find((req) => req.id === id);
                if (!absenceRequest) throw new Error('Absence request not found');

                return (
                    <AbsenceRequestDeleteDialog
                        absenceRequest={absenceRequest}
                        onCancel={onClose}
                        onSuccess={() => {
                            refreshAbsenceRequests();
                            if (absenceRequest.absenceId) {
                                refreshAbsenceTransactions();
                            }
                            onClose();
                        }}
                    />
                );
            });
        },
        [visibleOpenRequests, refreshAbsenceRequests, refreshAbsenceTransactions, showDialog]
    );

    const handleRequestAbsenceChangeClick = useCallback(
        (absenceTransaction: IAbsenceTransactionResult) => {
            showDialog((onClose) => (
                <DialogWithCloseBtn onClose={onClose} open>
                    <AbsenceChangeRequestRegistration
                        absence={{
                            id: absenceTransaction.absence.absenceId,
                            absenceCode: absenceTransaction.absenceCode,
                            fromDate: absenceTransaction.absence.dateFrom,
                            toDate: absenceTransaction.absence.dateTo,
                            fromTime:
                                // both absenceTimeFrom and absenceTimeTo will be 00:00 if no time is set
                                absenceTransaction.absence.timeFrom === '00:00' &&
                                absenceTransaction.absence.timeTo === '00:00'
                                    ? null
                                    : absenceTransaction.absence.timeFrom,
                            toTime:
                                // both absenceTimeFrom and absenceTimeTo will be 00:00 if no time is set
                                absenceTransaction.absence.timeTo === '00:00' &&
                                absenceTransaction.absence.timeFrom === '00:00'
                                    ? null
                                    : absenceTransaction.absence.timeTo,
                            canRequestChangesStatus:
                                absenceTransaction.absence.canRequestChangesStatus,
                            clientId: absenceTransaction.clientId,
                            employeeId: absenceTransaction.employeeId,
                            departmentId: absenceTransaction.absence.departmentId,
                            orderId: absenceTransaction.absence.orderId,
                            projectId: absenceTransaction.absence.projectId,
                            freeDimension1Id: absenceTransaction.absence.freeDimension1Id,
                            freeDimension2Id: absenceTransaction.absence.freeDimension2Id,
                            notes: absenceTransaction.absence.notes
                        }}
                        onCancel={onClose}
                        onSuccess={() => {
                            refreshAbsenceRequests();
                            refreshAbsenceTransactions();
                            onClose();
                        }}
                    />
                </DialogWithCloseBtn>
            ));
        },
        [showDialog, refreshAbsenceRequests, refreshAbsenceTransactions]
    );

    return (
        <>
            {visibleOpenRequests.map((request) => {
                const timeStr =
                    request.fromTime && request.toTime
                        ? `${request.fromTime} - ${request.toTime}`
                        : undefined;
                const absenceDescStr = joinAbsenceRequestDescriptionsIntoString(request, true);
                const absenceRequestDescription = timeStr
                    ? `${timeStr} | ${absenceDescStr}`
                    : absenceDescStr;

                return (
                    <AbsenceRequestDetailCard
                        key={request.absenceCode + request.id + request.departmentId}
                        title={request.absenceCodeDescription}
                        description={absenceRequestDescription}
                        onEditClick={() => handleEditClick(request.id)}
                        onDeleteClick={() => handleDeleteClick(request.id)}
                        isDeletable
                        isEditable
                    />
                );
            })}
            {visibleAbsenceTransactionsForSelectedDate.map((transaction, index) => {
                const absenceDescription = `${transaction.timeFrom} - ${transaction.timeTo} | ${transaction.departmentDescription}`;
                const canRequestChangesStatus =
                    transaction.absence?.canRequestChangesStatus ??
                    CanRequestAbsenceChangesStatus.CLOSED;

                return (
                    <AbsenceTransactionDetailCard
                        title={`${transaction.absenceCodeDescription}`}
                        key={
                            // eslint-disable-next-line react/no-array-index-key
                            transaction.absenceCode +
                            transaction.timeFrom +
                            transaction.departmentId +
                            index
                        }
                        // Index is added to avoid duplicate keys because absences with multiple time
                        // categories appear multiple times with no way to distinguish between them
                        description={absenceDescription}
                        allowRequestChange={
                            canRequestChangesStatus === CanRequestAbsenceChangesStatus.OPEN
                        }
                        onRequestChangeClick={() => handleRequestAbsenceChangeClick(transaction)}
                    />
                );
            })}
        </>
    );
}
