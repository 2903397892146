import { useFetchArray } from 'hooks/useFetch';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';
import { t } from 'i18next';
import dateI18n, { FIXED_LOCALIZED_DATE_FORMAT } from 'appI18n/dateI18n';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import ErrorMessage from 'components/error/ErrorMessage';
import { FlexiTimeBalance, FlexiTimeTransaction } from '../Balances.types';
import FlexiBalanceTableView from './FlexiBalanceTableView';

export default function FlexiBalanceTab() {
    const year = `${new Date().getFullYear()}`;
    const [
        flexiTimeBalances,
        { isLoading: isLoadingBalances, errorMessage: errorMessageBalances }
    ] = useFetchArray<FlexiTimeBalance>('getFlexiTimeBalance', { reqData: { year } });

    const [
        flexiTimeTransactions,
        { isLoading: isLoadingTransactions, errorMessage: errorMessageTransactions }
    ] = useFetchArray<FlexiTimeTransaction>('getFlexiTimeTransactions', { reqData: { year } });

    const isLoading = isLoadingBalances || isLoadingTransactions;
    const isSlimViewport = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    return (
        <>
            <Typography
                component="h1"
                variant="h5"
                gutterBottom
                sx={{
                    textTransform: 'uppercase'
                }}
            >
                {t('balances.flexitime.pageTitle')}
            </Typography>
            <Typography variant="h6" component="h2">
                {t('balances.flexitime.summaryTitle')}
            </Typography>
            <FlexiBalanceTableView
                isDense={isSlimViewport}
                isLoading={isLoadingBalances}
                errorMessage={errorMessageBalances}
                flexiTimeBalances={flexiTimeBalances}
            />
            <Typography variant="h6" component="h2" mt={2}>
                {t('balances.flexitime.transactionsTitle')}
            </Typography>
            {errorMessageTransactions && <ErrorMessage message={errorMessageTransactions} />}
            {!errorMessageTransactions && flexiTimeTransactions.length > 0 && (
                <TableContainer component={Paper}>
                    <Table
                        size="small"
                        aria-label={t('balances.flexitime.transactionsTable.label')}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                    {t('balances.flexitime.transactionsTable.day')}
                                </TableCell>
                                <TableCell>
                                    {t('balances.flexitime.transactionsTable.date')}
                                </TableCell>
                                <TableCell>
                                    {t('balances.flexitime.transactionsTable.description')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('balances.flexitime.transactionsTable.hours')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {flexiTimeTransactions.length === 0 && (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" colSpan={4}>
                                        {t('balances.noData')}
                                    </TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            )}
                            {flexiTimeTransactions.map((row) => {
                                const date = dateI18n(row.inDate);

                                return (
                                    <TableRow
                                        key={date.unix()}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell
                                            sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                        >
                                            {date.format('dddd')}
                                        </TableCell>
                                        <TableCell>
                                            {date.format(FIXED_LOCALIZED_DATE_FORMAT)}
                                        </TableCell>

                                        <TableCell>{row.accountDescription}</TableCell>
                                        <TableCell align="right">
                                            {row.totalAmountInHours.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
