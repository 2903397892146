/**
 * This turned into a bit of a strange case as create and update differs quite a bit when it comes to input names.
 * Ideally it would be a case of a couple of extra 'original'-prefixed properties necessary to indicate edit mode.
 *
 * Where the config is divided, each mode has their own function, for now.
 *
 * Thought about splitting it into two hooks, but they'd both have to be used in the component using them (as
 * components can't conditionally run hooks)
 *
 * Other than that, there's also the case of respecting the registration rules obtained from the remote server
 * which has been mostly dealt with in shared functions.
 */

import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import {
    DimensionInputName,
    DimensionInputNameUpdate,
    DimensionName,
    DimensionReferencePropertyName
} from 'models/TimeRegistrationModels';
import { fromPairs, isNull, mapKeys, pick } from 'lodash';
import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import {
    getDimensionNameAsInputNameForHourRegistration,
    pickDimensionPropsForInput
} from 'utils/dimension';
import { keyByAndFill } from 'utils/object';
import { chopSuffix } from 'utils/string';
import { object, string, ZodString } from 'zod';
import customValidators from 'utils/customValidators';
import { useMemo } from 'react';
import { DimensionInputRules } from 'features/misc/dimensionInput';
import { ValidationMode } from 'react-hook-form';
import { i18n } from 'appI18n';
import useDateOutBinder from './useDateOutBinder';
import useSuggestedTimeIn from './useSuggestedTimeIn';

export type PostHourRegistrationPayload = ITimeTransactionDetailResult;

type DimensionValidationRulesCreate = Partial<Record<DimensionInputName, ZodString>>;
type DimensionValidationRulesUpdate = Partial<Record<DimensionInputNameUpdate, ZodString>>;
export function getDimensionValidationRules(
    dimensionInputRules: DimensionInputRules
): DimensionValidationRulesCreate;
export function getDimensionValidationRules(
    dimensionInputRules: DimensionInputRules,
    transaction: ITimeTransactionDetailResult
): DimensionValidationRulesUpdate;

export function getDimensionValidationRules(
    dimensionInputRules: DimensionInputRules,
    transaction?: ITimeTransactionDetailResult
): DimensionValidationRulesCreate | DimensionValidationRulesUpdate {
    const formMode = transaction ? 'update' : 'create';

    const dimensionInputNamesRequired = dimensionInputRules
        .filter((rule) => rule.isRequired)
        .map((rule) => getDimensionNameAsInputNameForHourRegistration(rule.name, formMode));
    const dimensionInputNamesOptional = dimensionInputRules
        .filter((rule) => !rule.isRequired)
        .map((rule) => getDimensionNameAsInputNameForHourRegistration(rule.name, formMode));

    // Hva gjelder tekst; kun fått fremprovosert ved å fjerne påkrevd-property på select dropdown. Den vil kunne dukke opp dersom det er en option med tom verdi - som er greit å ha tatt høyde for.
    const rulesRequired = keyByAndFill(
        dimensionInputNamesRequired,
        string().min(1, i18n.t('pleaseSelect') || undefined) // TODO:: i18n.t _should_ be accessed be passed in from context to be sure it works properly.
    );
    const rulesOptional = keyByAndFill(dimensionInputNamesOptional, string());

    return {
        ...rulesRequired,
        ...rulesOptional
    };
}

function getRegistrationSchemaForCreate(dimensionInputRules: DimensionInputRules) {
    const validationRulesForCreate = getDimensionValidationRules(dimensionInputRules);
    // TODO:: Regex validaiton for both time and date fields
    return object({
        dateIn: customValidators.date(),
        timeIn: customValidators.time(),
        dateOut: customValidators.dateOptional(),
        timeOut: customValidators.timeOptional(),
        text: string(),
        ...validationRulesForCreate
    });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getRegistrationSchemaForUpdate(
    dimensionInputRules: DimensionInputRules,
    transaction: ITimeTransactionDetailResult
) {
    const validationRulesForUpdate = getDimensionValidationRules(dimensionInputRules, transaction);

    // TODO:: Dynamically change optional according to valid newDateOut?
    return object({
        existingDateIn: customValidators.date(),
        existingTimeIn: customValidators.time(),
        newDateIn: customValidators.date(),
        newTimeIn: customValidators.time(),
        newDateOut: customValidators.dateOptional(),
        newTimeOut: customValidators.timeOptional(),
        newText: string(),
        ...validationRulesForUpdate
    });
}

// TODO:: Improve types within this
type DimensionInputValues = Partial<Record<DimensionInputName, string>>;
type DimensionInputValuesUpdate = Partial<Record<DimensionInputNameUpdate, string>>;
export function getDimensionDefaultValues(
    dimensionInputRules: DimensionInputRules
): DimensionInputValues;
export function getDimensionDefaultValues(
    dimensionInputRules: DimensionInputRules,
    transaction: ITimeTransactionDetailResult
): DimensionInputValuesUpdate;
export function getDimensionDefaultValues(
    dimensionInputRules: DimensionInputRules,
    transaction?: ITimeTransactionDetailResult
): DimensionInputValues | DimensionInputValuesUpdate {
    const formMode = transaction ? 'update' : 'create';
    const dimensionNamesToInclude = dimensionInputRules.map((rule) => rule.name);

    // Empty values
    const dimensionInputNames = dimensionNamesToInclude.map((dimensionName) =>
        getDimensionNameAsInputNameForHourRegistration(dimensionName, formMode)
    );
    const emptyValues = keyByAndFill(dimensionInputNames, ''); // TODO:: keyByAndFill is incorrectly typed. In this case the result is Partial.

    if (!transaction) {
        // Values from settings
        const rulesWithDefaultValue = dimensionInputRules.filter(
            (rule) => !isNull(rule.defaultValue)
        );
        const dimensionValuePairsFromSettings = rulesWithDefaultValue.map((rule) => [
            getDimensionNameAsInputNameForHourRegistration(rule.name, formMode),
            rule.defaultValue as string // TODO:: type non-null better
        ]);
        const dimensionValuesFromSettings: DimensionInputValues | DimensionInputValuesUpdate =
            fromPairs(dimensionValuePairsFromSettings); // TODO:: fromPairs should at least be able to infer Record<string, string>.

        return {
            ...emptyValues,
            ...dimensionValuesFromSettings
        };
    }

    // Values from stored object, if provided
    let storedValues: DimensionInputValues | DimensionInputValuesUpdate = {};
    if (transaction) {
        const dimensionPropNamesRelevant = dimensionNamesToInclude.map(
            (dimensionName) => `${dimensionName}Id`
        );

        const dimensionPropsFromObject = pickDimensionPropsForInput(transaction);
        const dimensionPropsFromObjectRelevant = pick(
            dimensionPropsFromObject,
            dimensionPropNamesRelevant
        );

        // TODO:: Inferred types here would be preferable
        storedValues = mapKeys(dimensionPropsFromObjectRelevant, (_value, key) => {
            const keyAsDimensionPropertyName = key as DimensionReferencePropertyName;
            const dimensionNameFromKey = chopSuffix(
                keyAsDimensionPropertyName,
                'Id'
            ) as DimensionName; // Should be possible to infer type here, as input is limited to id-suffixed data.
            return getDimensionNameAsInputNameForHourRegistration(dimensionNameFromKey, formMode);
        });
    }

    return {
        ...emptyValues,
        ...storedValues
    };
}

export type DefaultValuesForCreate = {
    dateIn: string;
    timeIn: string;
    dateOut: string;
    timeOut: string;
    text: string;
} & DimensionInputValues;

function getDefaultValuesForCreate(
    dimensionInputRules: DimensionInputRules,
    dateInIsoFormat: string,
    suggestedTimeIn: string
): DefaultValuesForCreate {
    const dimensionDefaultValuesForCreate = getDimensionDefaultValues(dimensionInputRules);

    return {
        dateIn: dateInIsoFormat,
        timeIn: suggestedTimeIn,
        dateOut: '',
        timeOut: '',
        text: '',
        ...dimensionDefaultValuesForCreate
    };
}

function getDefaultValuesForUpdate(
    dimensionInputRules: DimensionInputRules,
    transaction: ITimeTransactionDetailResult
) {
    const dimensionDefaultValuesForUpdate = getDimensionDefaultValues(
        dimensionInputRules,
        transaction
    );

    return {
        existingDateIn: transaction.dateIn || '',
        existingTimeIn: transaction.timeIn || '',
        newDateIn: transaction.dateIn ?? '',
        newTimeIn: transaction.timeIn ?? '',
        newDateOut: transaction.dateOut ?? '',
        newTimeOut: transaction.timeOut ?? '',
        newText: transaction.comment ?? '',
        ...dimensionDefaultValuesForUpdate
    };
}

export default function useHourRegistrationForm(
    dateInIsoFormat: string,
    isCheckingIn: boolean,
    dimensionInputRules: DimensionInputRules | null,
    transaction?: ITimeTransactionDetailResult,
    onSuccess?: () => void,
    prefilledDefaultValues?: DefaultValuesForCreate,
    formValidationMode?: keyof ValidationMode
) {
    const formTarget = transaction ? 'updateTimeTransaction' : '/createCustomTimeTransaction';

    const suggestedTimeIn = useSuggestedTimeIn(isCheckingIn, dateInIsoFormat);

    const defaultValues = useMemo(
        () =>
            prefilledDefaultValues ||
            (transaction
                ? getDefaultValuesForUpdate(dimensionInputRules || [], transaction)
                : getDefaultValuesForCreate(
                      dimensionInputRules || [],
                      dateInIsoFormat,
                      suggestedTimeIn
                  )),
        [prefilledDefaultValues, transaction, dimensionInputRules, dateInIsoFormat, suggestedTimeIn]
    );

    const schemaData = transaction
        ? getRegistrationSchemaForUpdate(dimensionInputRules || [], transaction)
        : getRegistrationSchemaForCreate(dimensionInputRules || []);

    const formWithApiIntegrationProps = useFormWithApiIntegration<PostHourRegistrationPayload>(
        formTarget,
        schemaData,
        defaultValues,
        { onSuccess, formValidationMode }
    );

    useDateOutBinder(formWithApiIntegrationProps);

    return {
        ...formWithApiIntegrationProps,
        defaultValues
    };
}
