import { Grid, Paper, Typography } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import HFFormProvider from 'ctx/HFFormProvider';
import HFHidden from 'components/hookForm/HFHidden';
import { t } from 'i18next';
import { DEFAULT_LOCALE_CODE } from 'appI18n';
import ErrorMessage from 'components/error/ErrorMessage';
import { useEmployeeSettings } from 'features/profile';
import HFDateRangePicker from 'components/hookForm/HFDateRangePicker';
import useReportsDateSelectionForm from './useReportsDateSelectionForm';
import { GetReportPayload } from './Reports.types';

type ReportDateSelectionFormProps = {
    reportId: string;
    onFormSuccess: (data: GetReportPayload) => void;
};

export default function ReportDateSelectionForm({
    onFormSuccess,
    reportId
}: ReportDateSelectionFormProps) {
    const formProps = useReportsDateSelectionForm(onFormSuccess);
    const { employeeSettings } = useEmployeeSettings();
    formProps.setValue('reportId', reportId);
    formProps.setValue('cultureCode', employeeSettings.language ?? DEFAULT_LOCALE_CODE);

    return (
        <HFFormProvider formProps={formProps}>
            <Paper sx={{ flexGrow: 1, display: 'flex', my: 2, p: 2 }}>
                <Grid container spacing={1} alignItems="center">
                    <HFHidden name="cultureCode" />
                    <HFHidden name="reportId" />

                    <Grid item xs={12} sm={8} lg={10}>
                        <HFDateRangePicker
                            sx={(theme) => ({
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: theme.spacing(2)
                            })}
                            fromPickerProps={{
                                name: 'fromDate',
                                label: t('reports.fromDate'),
                                fullWidth: true,
                                required: true
                            }}
                            toPickerProps={{
                                name: 'toDate',
                                label: t('reports.toDate'),
                                fullWidth: true,
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                        <ActionButton
                            size="large"
                            fullWidth
                            isloading={formProps.formState.isSubmitting}
                        >
                            {t('reports.submitBtn')}
                        </ActionButton>
                    </Grid>
                    {formProps.formState.isSubmitted && !formProps.successPayload?.hasResults && (
                        <Typography>{t('reports.emptyDataSet')}</Typography>
                    )}
                    {formProps.displayErrorMessage && (
                        <ErrorMessage>{formProps.displayErrorMessage}</ErrorMessage>
                    )}
                </Grid>
            </Paper>
        </HFFormProvider>
    );
}
