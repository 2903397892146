import { Close, Delete, Edit } from '@mui/icons-material';
import {
    Alert,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    useTheme
} from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import AbsenceRequest from 'models/AbsenceRequest';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { AbsenceRequestRegistration } from 'features/absence';
import { useDialog } from 'ctx/DialogCtx';
import { KeyValueListItem } from 'components/KeyValueList/KeyValueList';
import useReadableDate from 'hooks/useReadableDate';
import { AbsenceRequestsContext } from 'features/absence/providers/absenceRequests/AbsenceRequestsProvider';
import { useCustomDimensionLabels } from 'features/misc/dimensionInput';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import { Link } from 'react-router-dom';
import { useSmartAbsenceTimespanString } from '../util';
import AbsenceAvatar from '../components/AbsenceAvatar';
import AbsenceRequestDeleteDialog from './AbsenceRequestDeleteDialog';

type AbsenceDetailsProps = {
    absence: AbsenceRequest;
};

export default function AbsenceDetails({ absence }: AbsenceDetailsProps) {
    const { t } = useTranslation();
    const { showDialog } = useDialog();
    const { refreshAbsenceRequests } = useContext(AbsenceRequestsContext);
    const readableProcessDate = useReadableDate(absence.processedDate ?? '');
    const isApproved = absence.absenceRequestStatus === 'Accepted';
    const isRejected = absence.absenceRequestStatus === 'Rejected';
    const isAwaitingApproval = absence.absenceRequestStatus === 'Created';
    const { customDimensionLabels, isLoading } = useCustomDimensionLabels();

    const handleEditClick = useCallback(() => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <AbsenceRequestRegistration
                    date={absence.fromDate}
                    absenceRequest={absence}
                    onCancel={onClose}
                    onSuccess={() => {
                        refreshAbsenceRequests();
                        onClose();
                    }}
                />
            </DialogWithCloseBtn>
        ));
    }, [absence, refreshAbsenceRequests, showDialog]);

    const handleDeleteClick = useCallback(() => {
        showDialog((onClose) => (
            <AbsenceRequestDeleteDialog
                absenceRequest={absence}
                onCancel={onClose}
                onSuccess={() => {
                    refreshAbsenceRequests();
                    onClose();
                }}
            />
        ));
    }, [absence, refreshAbsenceRequests, showDialog]);

    const absenceDataEntries: Array<KeyValueListItem> = useMemo(() => {
        if (!absence) return [];

        // Order of dimensions should be the same as in the absence request form
        // @see TimeRegistrationModel::allDimensionNamesSorted
        const unfilteredItems: Array<KeyValueListItem | undefined> = [
            {
                key: customDimensionLabels?.departmentDescription || t('dimension.department'),
                value: absence.departmentDescription || ''
            },
            {
                key: customDimensionLabels?.orderDescription || t('dimension.order'),
                value: absence.orderDescription || ''
            },
            {
                key: customDimensionLabels?.projectDescription || t('dimension.project'),
                value: absence.projectDescription || ''
            },
            {
                key: customDimensionLabels?.fridim1Description || t('dimension.fridim1'),
                value: absence.fridim1Description || ''
            },
            {
                key: customDimensionLabels?.fridim2Description || t('dimension.fridim2'),
                value: absence.fridim2Description || ''
            },
            {
                key: t('absenceRequestRegistration.message'),
                value: absence.text || ''
            }
        ];

        return unfilteredItems.filter(
            (item): item is KeyValueListItem =>
                item !== null && item !== undefined && item.value !== ''
        );
    }, [
        absence,
        customDimensionLabels?.departmentDescription,
        customDimensionLabels?.fridim1Description,
        customDimensionLabels?.fridim2Description,
        customDimensionLabels?.orderDescription,
        customDimensionLabels?.projectDescription,
        t
    ]);

    const theme = useTheme();
    const smartTimeSpanStr = useSmartAbsenceTimespanString(absence);

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    return (
        <Card>
            <CardHeader
                title={`${absence.absenceCodeDescription} (${absence.absenceCode})`}
                subheader={smartTimeSpanStr}
                avatar={<AbsenceAvatar absence={absence} />}
                action={
                    <IconButton aria-label="close" component={Link} to="/absence">
                        <Close />
                    </IconButton>
                }
            />
            <Divider variant="middle" />
            <CardContent>
                {isRejected && (
                    <Alert severity="warning" sx={{ border: 1, borderColor: 'warning.light' }}>
                        {t('absenceRequestOverview.rejectedInfo', {
                            at: readableProcessDate,
                            by: absence.processedBy
                        })}
                    </Alert>
                )}
                {isAwaitingApproval && (
                    <Alert severity="info" sx={{ border: 1, borderColor: 'info.light' }}>
                        {t('absenceRequestOverview.notProcessed')}
                    </Alert>
                )}
                {isApproved && (
                    <Alert severity="success" sx={{ border: 1, borderColor: 'success.light' }}>
                        {t('absenceRequestOverview.approvedInfo', {
                            at: readableProcessDate,
                            by: absence.processedBy
                        })}
                    </Alert>
                )}
                <List>
                    {absenceDataEntries.map((item) => (
                        <ListItem key={item.key}>
                            <ListItemText
                                secondary={item.value}
                                secondaryTypographyProps={{
                                    variant: 'body1',
                                    fontWeight: theme.typography.fontWeightBold,
                                    fontFamily: 'GT-Walsheim-Bold',
                                    color: theme.palette.text.primary
                                }}
                                primary={item.key}
                                primaryTypographyProps={{
                                    variant: 'subtitle2'
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            {isAwaitingApproval && (
                <CardActions>
                    <ActionButton onClick={handleEditClick} variant="outlined" startIcon={<Edit />}>
                        {t('actions.edit')}
                    </ActionButton>
                    <ActionButton
                        onClick={handleDeleteClick}
                        variant="outlined"
                        startIcon={<Delete />}
                    >
                        {t('actions.delete')}
                    </ActionButton>
                </CardActions>
            )}
        </Card>
    );
}
