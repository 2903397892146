import { PasswordPolicy } from 'models/MyCapitechClientSettings';

export default function runPasswordPolicyCheck(
    password: string,
    passwordPolicy: PasswordPolicy
): Partial<Record<keyof PasswordPolicy | 'notJustWhiteSpace', boolean>> {
    const {
        minimumLength,
        maximumLength,
        mustContainNumbers,
        mustContainSpecialCharacters,
        mustContainUpperAndLower
    } = passwordPolicy;

    return {
        notJustWhiteSpace: password === '' || password.trim() !== '',
        ...(minimumLength ? { minimumLength: password.length >= minimumLength } : {}),
        ...(maximumLength ? { maximumLength: password.length <= maximumLength } : {}),
        ...(mustContainNumbers ? { mustContainNumbers: /\d/.test(password) } : {}),
        ...(mustContainSpecialCharacters
            ? { mustContainSpecialCharacters: /[^a-zA-ZÆæØøÅå\d\s]/.test(password) }
            : {}),
        ...(mustContainUpperAndLower
            ? {
                  mustContainUpperAndLower: /[a-zæøå]/.test(password) && /[A-ZÆØÅ]/.test(password)
              }
            : {})
    };
}
