/**
 * Requires a LocalizationProvider (DateLocalizationProvider in our case) to be set up before use.
 *
 * If moving away from static display, be sure to add label as a required property.
 */
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import dateI18n from 'appI18n/dateI18n';
import useArrowKeyReleased from 'hooks/useArrowKeyReleased';
import { DateI18n, getFullWeekdayName, getShortWeekdayName } from 'appI18n';
import { DateEventSetsByDate } from './DateEventGroupWrapper';
import CustomDay from './CustomDay';
import CustomHeader from './CustomHeader';

const BREAK_POINT_CALENDAR_MODE_CHANGE = 'sm';

export type CalendarProps = {
    selectedDate: Date | null;
    onDateChange: (newDate: Date | null) => void;
    onMonthChange: (dateInMonth: Date) => void;
    dateEventSets: DateEventSetsByDate;
    disableDateSelection?: boolean;
};

export default function Calendar({
    onDateChange,
    onMonthChange,
    dateEventSets,
    selectedDate,
    disableDateSelection
}: CalendarProps) {
    const [monthInFocusDate, setMonthInFocusDate] = useState(new Date());

    const isMinMode = useMediaQuery(useTheme().breakpoints.down(BREAK_POINT_CALENDAR_MODE_CHANGE));

    const pixelsNeededUpToShowFirstWeekNumber = 4;

    const selectedDateAsCalendarValue = useMemo(
        () => (selectedDate ? dateI18n(selectedDate) : null),
        [selectedDate]
    );

    const handleMonthChange = (newDateInMonth: DateI18n | null) => {
        if (newDateInMonth) {
            onDateChange(newDateInMonth.toDate());
            onMonthChange(newDateInMonth.toDate());
            setMonthInFocusDate(newDateInMonth.toDate());
        }
    };

    // Catch month navigation through arrow keys. The library doesn't provide this information. Keep an eye on https://github.com/mui/mui-x/pull/6954
    const calendarContainer = useRef<HTMLDivElement>(null);
    useArrowKeyReleased(true, () => {
        // Manually lookup which date has focus, as the library doesn't provide this information
        const focusedDateElement = calendarContainer.current?.querySelector(
            '.MuiPickersDay-root.Mui-focusVisible'
        ) as HTMLButtonElement | null;

        if (!focusedDateElement) {
            return;
        }

        const timestampFocusedDate = Number(focusedDateElement.getAttribute('data-timestamp'));
        const dateInFocus = new Date(timestampFocusedDate);
        if (dateInFocus && dateInFocus.getMonth() !== monthInFocusDate.getMonth()) {
            // Only update month if it's changed.
            handleMonthChange(dateI18n(dateInFocus));
        }
    });

    return (
        <Box
            ref={calendarContainer}
            sx={(theme) => ({
                '& .MuiPickersDay-dayOutsideMonth:not(.Mui-selected) .date-container, & .week-end-day:not(.Mui-selected) .date-container':
                    {
                        opacity: 0.5
                    },
                '& .MuiPickersCalendarHeader-labelContainer': {
                    cursor: 'default', // Removes pointer cursor meant to trigger year/month selection
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    textTransform: 'uppercase',
                    fontSize: 'min(6vw, 2em)' // Text should not be bigger than 2em, and should shrink on smaller screens
                },
                '& .MuiPickersCalendarHeader-label': {
                    lineHeight: '1em'
                },
                '& .MuiPickersDay-root': {
                    display: 'flex',
                    flexDirection: 'column', // Content is layed out from top to bottom,
                    aspectRatio: '1/1',
                    height: 'auto',
                    overflow: 'hidden'
                },
                /* START flexibility */
                '& .MuiDateCalendar-root > div, & .MuiDateCalendar-root': {
                    // Loosens up on container causing overflow issues on size expansion
                    width: '100%',
                    maxHeight: 'none',
                    height: 'auto'
                },
                '& .MuiDateCalendar-root': {
                    width: '100%'
                },
                '& .MuiDayCalendar-weekContainer, & .MuiDayCalendar-header': {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    my: 0
                },
                '& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel': {
                    flex: 1
                },
                /* STOP flexibility */
                '& .MuiDayCalendar-weekContainer': {
                    borderTop: '1px solid', // Those horizontal lines all over the date picker
                    borderColor: 'divider'
                },
                /* START display week numbers like iOS */
                '& .calenda-with-week-numbers': {
                    '.MuiDayCalendar-weekContainer, .MuiDayCalendar-header': {
                        position: 'relative',
                        marginLeft: '25px'
                    },
                    '.MuiDayCalendar-weekNumberLabel, .MuiDayCalendar-weekNumber': {
                        position: 'absolute',
                        left: '-35px'
                    },
                    '.MuiDayCalendar-weekNumber': {
                        top: '-18px'
                    },
                    '.MuiDayCalendar-weekContainer:nth-of-type(1)': {
                        marginTop: `${pixelsNeededUpToShowFirstWeekNumber}px`
                    },
                    '.MuiDayCalendar-header': {
                        marginBottom: `-${pixelsNeededUpToShowFirstWeekNumber}px` // This is icky, but it's way simpler to do this than to reduce just the spacing beneath the header text and keep the space above as before
                    }
                },
                /* STOP display week numbers like iOS */
                [theme.breakpoints.up(BREAK_POINT_CALENDAR_MODE_CHANGE)]: {
                    /* START Trick to create those vertical lines all over the date picker. Using a psuedo element leaves surrounding elements in peace. */
                    '& .MuiDayCalendar-weekContainer': {
                        columnGap: '1px' // Those vertical lines all over the date picker. Avoids border conflicts which'll arise when just using borderRight.
                    },
                    /* Earlier we could just apply a backgroundColor to the gutter, which was nice. Now there's a second psuedo-element fills the cell and floats over the flex gutter, with a 1px border on the right side */
                    '& .MuiPickersDay-root:not(:last-child)': {
                        overflow: 'visible'
                    },
                    '& .MuiPickersDay-root:not(:last-child)::after': {
                        content: '""',
                        position: 'absolute',
                        display: 'block',
                        top: 0,
                        right: -1,
                        bottom: 0,
                        left: 0,
                        borderRight: '1px solid',
                        borderColor: 'divider',
                        pointerEvents: 'none'
                    },
                    /* STOP trick to create those vertical lines */
                    /* START ensuring border on todays date behaves nice */
                    // Creates the border through a pseudo element which will eat away at the inner space -which there's room for-
                    // and leaves the surrounding elements in peace.
                    // With just a regular border the cell will expand to the sides, breaking our intended evenly spaced layout.
                    '& .MuiPickersDay-root.MuiPickersDay-today': {
                        borderWidth: '0px',
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            display: 'block',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            pointerEvents: 'none'
                        }
                    },
                    /* STOP ensuring border on todays date behaves nice */
                    '& .date-container': {
                        px: '4px'
                    },
                    '& .MuiDayCalendar-weekDayLabel, & .MuiPickersDay-root': {
                        borderRadius: '0px' // Borders should all be square
                    },
                    '& .MuiPickersDay-root': {
                        justifyContent: 'flex-start',
                        mx: 0 // Keeps columnGap on 1px
                    },
                    '& .MuiPickersDay-root > .date-container': {
                        alignSelf: 'flex-end'
                    },
                    '& .date-event-group-dot, & .date-event-dot': {
                        display: 'none' // Dots are only meant for min mode.
                    },
                    '& .date-event, & .date-event-group': {
                        overflow: 'hidden' // Prevents text from overflowing into cell to the right
                    },
                    '& .date-event': {
                        mx: '1px', // Space between events
                        mb: '1px', // Space between events. Only at the bottom, as we only want 1px between each.
                        // START stricting layout - regardless of content size
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignSelf: 'stretch',
                        textAlign: 'left'
                        // STOP stricting layout
                    },
                    '& .date-event-group': {
                        // Labels should be displayed from top to bottom
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    '& .date-events-container': {
                        width: '100%', // Prevents event labels from overflowing onto cells horizontally nearby
                        overflow: 'hidden' // Prevents labels from overflowing onto cells vertically nearby
                    }
                },
                [theme.breakpoints.down(BREAK_POINT_CALENDAR_MODE_CHANGE)]: {
                    '& .MuiPickersDay-root.Mui-selected.MuiPickersDay-today': {
                        p: '1px' // Prevents messing around with neighboring elements
                    },
                    '& .date-event-group .date-event-dot': {
                        display: 'none' // Hide dots to events within groups, so only dots to non-grouped events and dots for an event group are displayed
                    },
                    '& .date-event-text': {
                        display: 'none' // Event text is only displayed in full mode
                    },
                    '& .date-events-container': {
                        display: 'flex',
                        flexDirection: 'row'
                    },
                    '& .MuiPickersDay-root > .date-container': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '100%',
                        fontSize: 'max(3vw, 12px)' // Small enough to fit text in date cells next to each other as a month changes. In these cases the short name of both months are displayed in addition to the date.
                    },
                    '& .MuiPickersDay-root > .date-events-container': {
                        // Dates should concistently appear on the same position in each date cell and dots should appear below. This is the current trick to make it so.
                        position: 'absolute',
                        top: '65%',
                        scale: '.65'
                    },
                    '& .date-events-container > .date-event-group:nth-of-type(n+5)': {
                        // We can't fit any more than 4
                        // TODO:: Consider if we can resolve this differently. Just cutting off overflowing elements without implying there's more ain't ideal.
                        display: 'none'
                    }
                }
            })}
        >
            <DateCalendar<DateI18n>
                disabled={disableDateSelection}
                displayWeekNumber
                className="calenda-with-week-numbers"
                reduceAnimations
                views={['day']}
                value={selectedDateAsCalendarValue}
                onChange={(newDate: DateI18n | null) => {
                    onDateChange(newDate?.toDate() || null);

                    const hasClickedDayOutsideMonth =
                        newDate && newDate.month() !== monthInFocusDate.getMonth();
                    if (hasClickedDayOutsideMonth) {
                        setMonthInFocusDate(newDate.toDate());
                        // No onMonthChange. It would nullify selectedDate.
                    }
                }}
                onMonthChange={(newDateInMonth: DateI18n | null) => {
                    if (newDateInMonth) {
                        onDateChange(newDateInMonth.toDate());
                        onMonthChange(newDateInMonth.toDate());
                        setMonthInFocusDate(newDateInMonth.toDate());
                    }
                }}
                dayOfWeekFormatter={(shortDay) =>
                    isMinMode ? getShortWeekdayName(shortDay) : getFullWeekdayName(shortDay)
                }
                showDaysOutsideCurrentMonth
                slots={{
                    day: (props) => CustomDay({ ...props, dateEventSets, monthInFocusDate }),
                    calendarHeader: (props) =>
                        CustomHeader({ ...props, onMonthClick: () => onDateChange(null) })
                }}
            />
        </Box>
    );
}

Calendar.defaultProps = {
    disableDateSelection: false
};
