import { Container, Typography } from '@mui/material';
import { useFetchArray } from 'hooks/useFetch';
import { SyntheticEvent, useEffect, useState } from 'react';
import { DEFAULT_LOCALE_CODE } from 'appI18n';
import { useTranslation } from 'react-i18next';
import { useEmployeeSettings } from 'features/profile';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import ReportAccordion from './ReportsAccordion';
import { ReportHeader } from './Reports.types';
import ReportAccordionWithDateForm from './ReportsAccordionWithDateForm';
import './report-table-css-fix.css';

export default function Reports() {
    const { employeeSettings } = useEmployeeSettings();
    const [avaliableReports, { isLoading }] = useFetchArray<ReportHeader>('getAvaliableReports', {
        reqData: { cultureCode: employeeSettings.language ?? DEFAULT_LOCALE_CODE }
    });
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (!isLoading && avaliableReports.length > 0) {
            setExpanded(`panel${avaliableReports[0].id}`);
        }
    }, [avaliableReports, isLoading]);

    const { t } = useTranslation();

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 2 }}>
            <Typography
                component="h1"
                variant="h5"
                gutterBottom
                sx={{
                    textTransform: 'uppercase'
                }}
            >
                {t('reports.pageTitle')}
            </Typography>
            {avaliableReports.map((report) => {
                const panel = `panel${report.id}`;
                if (report.requiresFromAndToDates) {
                    return (
                        <ReportAccordionWithDateForm
                            onChangeCallback={handleChange(panel)}
                            isExpanded={expanded === panel || avaliableReports.length === 1}
                            report={report}
                            key={panel}
                        />
                    );
                }

                return (
                    <ReportAccordion
                        onChangeCallback={handleChange(panel)}
                        isExpanded={expanded === panel || avaliableReports.length === 1}
                        reportHeader={report}
                        key={panel}
                    />
                );
            })}
        </Container>
    );
}
