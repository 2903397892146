import { CSSObject, useTheme } from '@mui/material';

export default function useStickyBelowAppBarSx() {
    const theme = useTheme();
    return {
        position: 'sticky',
        top: ((theme.components?.MuiAppBar?.styleOverrides?.root as CSSObject)?.height ||
            64) as number,
        zIndex: theme.zIndex.appBar - 1
    };
}
