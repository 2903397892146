import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { useFetch } from 'hooks/useFetch';
import { InputOption } from 'components/Input/Dropdown/InputOption';
import { dayjs } from 'appI18n';
import { times } from 'lodash';
import {
    DutyWishRegistrationContextType,
    GetPossibleValuesForDutyWishFilterPayload
} from './DutyWishRegistrationProvider.types';
import { useDepartmentAndDutyFilter } from '../departmentAndDutyFilter';

type DutyWishRegistrationProviderProps = PropsWithChildren;

export const DutyWishRegistrationContext = createContext<DutyWishRegistrationContextType>(null!);

export default function DutyWishRegistrationProvider({
    children
}: DutyWishRegistrationProviderProps) {
    const [inputValues, setInputValues] = useState<DutyWishRegistrationContextType['values']>({
        requestedDaysOfWeek: times(7, (dayOfWeek) => dayOfWeek + 1)
    });
    const [dateValuesValidFor, setDateValuesValidFor] =
        useState<DutyWishRegistrationContextType['dateValuesValidFor']>(null);

    const [
        dutyWishFilterPossibilities,
        {
            errorMessage: errorMessageDutyWishFilterPossibilities,
            isLoading: isLoadingDutyWishFilterPossibilities
        }
    ] = useFetch<GetPossibleValuesForDutyWishFilterPayload>('getPossibleValuesForDutyWishFilter');
    const {
        isLoading: isLoadingDutyFilter,
        values: dutyWishFilter,
        errorMessage: errorMessageDutyFilter
    } = useDepartmentAndDutyFilter();

    const isLoading = isLoadingDutyFilter || isLoadingDutyWishFilterPossibilities;
    const errorMessage = errorMessageDutyFilter || errorMessageDutyWishFilterPossibilities || null;

    const allDaysOfWeekOptions: Array<InputOption<number>> = useMemo(
        () =>
            times(7, (dayOfWeek) => ({
                value: dayOfWeek + 1,
                label: dayjs()
                    .day(dayOfWeek + 1)
                    .format('dddd'),
                isSelectable: true
            })),
        []
    );

    const optionsFromServer: Pick<
        DutyWishRegistrationContextType['optionsAvailable'],
        'departments' | 'dutyTypes'
    > = useMemo(
        () => ({
            departments: dutyWishFilter?.departmentIds
                .map<InputOption<number> | undefined>((departmentId) => {
                    const matchingDepartment = dutyWishFilterPossibilities?.departments.find(
                        (department) => department.id === departmentId
                    );
                    return matchingDepartment
                        ? {
                              value: matchingDepartment.id,
                              label: `${matchingDepartment.id} ${matchingDepartment.description}`,
                              isSelectable: true
                          }
                        : undefined;
                })
                .filter((possibleOption): possibleOption is InputOption<number> =>
                    Boolean(possibleOption)
                ),
            dutyTypes: dutyWishFilter?.dutyTypeIds
                .map<InputOption<number> | undefined>((dutyTypeId) => {
                    const matchingDutyType = dutyWishFilterPossibilities?.dutyTypes.find(
                        (dutyType) => dutyType.id === dutyTypeId
                    );
                    return matchingDutyType
                        ? {
                              value: matchingDutyType.id,
                              label: matchingDutyType.description,
                              isSelectable: true
                          }
                        : undefined;
                })
                .filter((possibleOption): possibleOption is InputOption<number> =>
                    Boolean(possibleOption)
                )
        }),
        [
            dutyWishFilter?.departmentIds,
            dutyWishFilter?.dutyTypeIds,
            dutyWishFilterPossibilities?.departments,
            dutyWishFilterPossibilities?.dutyTypes
        ]
    );

    // Make all options selected once options arrive
    useEffect(() => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            requestedDepartmentIds: optionsFromServer.departments?.map(
                (departmentOption) => departmentOption.value
            ),
            requestedDutyTypeIds: optionsFromServer.dutyTypes?.map(
                (dutyTypeOption) => dutyTypeOption.value
            )
        }));
    }, [optionsFromServer]);

    const value: DutyWishRegistrationContextType = useMemo(
        () => ({
            values: inputValues,
            setValue: (key, keyValue) => {
                setInputValues((prevInputValues) => {
                    // Assuming returning the same object does not trigger rerender (https://github.com/facebook/react/issues/20817)
                    if (prevInputValues[key] === keyValue) return prevInputValues;
                    return { ...prevInputValues, [key]: keyValue };
                });
            },
            dateValuesValidFor,
            setDateValuesValidFor,
            setValues: setInputValues,
            optionsAvailable: {
                isLoading,
                errorMessage,
                daysOfWeek: allDaysOfWeekOptions,
                departments: optionsFromServer.departments,
                dutyTypes: optionsFromServer.dutyTypes
            }
        }),
        [
            inputValues,
            dateValuesValidFor,
            isLoading,
            errorMessage,
            allDaysOfWeekOptions,
            optionsFromServer.departments,
            optionsFromServer.dutyTypes
        ]
    );

    return (
        <DutyWishRegistrationContext.Provider value={value}>
            {children}
        </DutyWishRegistrationContext.Provider>
    );
}
