import { DatePicker as MuiDatePicker, pickersDayClasses } from '@mui/x-date-pickers';
import { useMemo } from 'react';
import { SxProps, Theme } from '@mui/material';
import { omit, pick } from 'lodash';
import dateI18n, { FIXED_LOCALIZED_DATE_FORMAT } from 'appI18n/dateI18n';
import { DATE_INPUT_IDENTIFIER_CLASS } from 'utils/input';
import useNativeInput from 'hooks/useNativeInput';
import DatePickerActionBar from './DatePickerActionBar';
import {
    DatePickerProps,
    MuiDatePickerPropsChangingValueFormat,
    datePickerPropNamesJustForTextFieldSlot as propNamesJustForTextFieldSlot
} from './DatePicker.types';
import NativeDatePicker from './NativeDatePicker';

export default function DatePicker({
    value,
    sourceFormat,
    onChange,
    compact,
    ...muiProps
}: DatePickerProps) {
    const muiPropsProxiedToDatePicker = omit(muiProps, [...propNamesJustForTextFieldSlot, 'sx']);
    const muiPropsProxiedToTextField = pick(muiProps, [...propNamesJustForTextFieldSlot, 'name']);

    const propsChangingValueFormat: MuiDatePickerPropsChangingValueFormat = useMemo(() => {
        let overrides: MuiDatePickerPropsChangingValueFormat = {};
        if (typeof onChange !== 'undefined' && typeof sourceFormat !== 'undefined') {
            overrides = {
                ...overrides,
                onChange: (evt, context) => {
                    onChange(evt ? evt.format(sourceFormat) : null, context);
                }
            };
        }

        if (value !== undefined) {
            overrides = {
                ...overrides,
                value: dateI18n(value, sourceFormat)
            };
        }
        return overrides;
    }, [sourceFormat, value, onChange]);

    const layoutSxVariatedByProps: SxProps<Theme> = compact
        ? {
              '& .MuiDateCalendar-root': {
                  height: 'auto'
              },
              '& .MuiPickersSlideTransition-root': {
                  minHeight: 'unset'
              },
              '& .MuiDayCalendar-monthContainer': {
                  position: 'unset'
              }
          }
        : {};

    const { preferNativeDate } = useNativeInput();

    if (preferNativeDate) {
        const props = pick(muiProps, ['inputRef']);
        return (
            <NativeDatePicker
                {...props}
                {...muiPropsProxiedToTextField}
                label={muiPropsProxiedToDatePicker.label}
                minDate={muiPropsProxiedToDatePicker.minDate}
                maxDate={muiPropsProxiedToDatePicker.maxDate}
                value={value}
                onChange={onChange}
            />
        );
    }

    return (
        <MuiDatePicker
            views={['year', 'month', 'day']}
            format={FIXED_LOCALIZED_DATE_FORMAT}
            closeOnSelect
            {...muiPropsProxiedToDatePicker}
            {...propsChangingValueFormat}
            slots={{
                actionBar: DatePickerActionBar
            }}
            reduceAnimations={muiPropsProxiedToDatePicker.reduceAnimations ?? compact}
            slotProps={{
                textField: {
                    ...muiPropsProxiedToTextField,
                    inputProps: { className: DATE_INPUT_IDENTIFIER_CLASS }
                },
                layout: {
                    sx: {
                        ...layoutSxVariatedByProps,
                        [`.${pickersDayClasses.root}.${pickersDayClasses.selected}.${pickersDayClasses.disabled}`]:
                            {
                                backgroundColor: 'primary.light',
                                color: 'primary.contrastText'
                            }
                    }
                },
                toolbar: {
                    hidden: true
                }
            }}
        />
    );
}
DatePicker.defaultProps = {
    sourceFormat: 'YYYY-MM-DD',
    value: undefined,
    onChange: undefined,
    compact: true
};
