import useAppSettings from 'hooks/useAppSettings';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    accessTokenHasExpired,
    loginAuth0,
    loginAzureAd,
    logoutAuth0,
    logoutAzureAd,
    refreshAuth
} from 'utils/auth';
import {
    cleanUpAuthCookies,
    getPreviouslySelectedClientIdCookie,
    setPreviouslySelectedClientIdCookie,
    setRefreshTokenCookie
} from 'utils/cookie';
import useErrorScreen from 'hooks/useErrorScreen';
import { useTranslation } from 'react-i18next';
import LoadingScreen from 'components/loading/LoadingScreen';
import {
    ExternalLoginProviderContextType,
    ExternalLoginProviderProps
} from './ExternalLoginProvider.types';

export const ExternalLoginProviderContext = createContext<ExternalLoginProviderContextType>(null!);

export default function ExternalLoginProvider({ children }: ExternalLoginProviderProps) {
    const settings = useAppSettings();
    const { setError } = useErrorScreen();
    const isExternalLoginEnabled =
        settings.loginSimployerAuth0 || settings.loginExclusivelyAzure || settings.loginAzure;
    const isInitialized = useRef(false);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();

    const location = useLocation();
    const urlParams = useMemo(
        () =>
            new URLSearchParams(
                // As of writing, backend sends 'isAuthenticated = false' instead of 'isAuthenticated=false'
                location.search.replaceAll('isAuthenticated%20=%20', 'isAuthenticated=')
            ),
        [location.search]
    );
    const isAuthenticatedParam = urlParams.get('isAuthenticated')?.trim();

    const provider = useMemo(() => {
        if (settings.loginSimployerAuth0) {
            return 'auth0';
        }
        if (settings.loginAzure || settings.loginExclusivelyAzure) {
            return 'azure';
        }
        return 'capitech';
    }, [settings.loginAzure, settings.loginExclusivelyAzure, settings.loginSimployerAuth0]);

    const login = useCallback(() => {
        if (provider === 'auth0') {
            loginAuth0(settings.signInUrl);
        }
        if (provider === 'azure') {
            loginAzureAd(settings.signInUrl);
        }
    }, [provider, settings.signInUrl]);

    const logOut = useCallback(() => {
        if (provider === 'auth0') {
            logoutAuth0(settings.signOutUrl);
        }
        if (provider === 'azure') {
            logoutAzureAd();
        }
    }, [provider, settings.signOutUrl]);

    useEffect(() => {
        if (!isExternalLoginEnabled) {
            if (isLoading) {
                setIsLoading(false);
            }
            return;
        }

        if (!isAuthenticatedParam) {
            const tokenIsExpired = accessTokenHasExpired();
            const shouldLogDirectlyInWithAd =
                settings.loginExclusivelyAzure && getPreviouslySelectedClientIdCookie();

            if (tokenIsExpired && (provider === 'auth0' || shouldLogDirectlyInWithAd)) {
                login();
            } else {
                setIsLoading(false);
            }
        }

        if (isInitialized.current) {
            return;
        }
        isInitialized.current = true;

        if (isAuthenticatedParam === 'true') {
            const refreshTokenParam = urlParams.get('my-capitech-refresh-token')!; // TODO: Don't require
            const clientId = urlParams.get('my-capitech-clientid')!;
            const url = new URL(window.location.href);

            // This is done to make sure we're starting with a clean slate
            cleanUpAuthCookies();

            refreshAuth(refreshTokenParam)
                .then(() => {
                    setRefreshTokenCookie(
                        refreshTokenParam,
                        new Date(Date.now() + 1000 * 60 * 60 * 24)
                    );
                    setPreviouslySelectedClientIdCookie(clientId);
                    window.history.replaceState({}, document.title, url.origin + url.pathname);
                    // window.location.href = url.origin + url.pathname;
                })
                .catch(() => {
                    setError(
                        {
                            iconName: 'authError',
                            title: t('authErrors.sessionError.title'),
                            message: t('authErrors.sessionError.message'),
                            action: {
                                title: t('authErrors.sessionError.button'),
                                onClick: () => {
                                    cleanUpAuthCookies();
                                    logOut();
                                }
                            }
                        },
                        true
                    );
                })
                .finally(() => setIsLoading(false));
        } else if (isAuthenticatedParam === 'false') {
            setIsLoading(false);
            setError(
                {
                    iconName: 'authError',
                    title: t('authErrors.unauthorizedUser.title'),
                    message: t('authErrors.unauthorizedUser.message'),
                    action: {
                        title: t('authErrors.unauthorizedUser.button'),
                        onClick: () => logOut()
                    }
                },
                true
            );
        }
    }, [
        isAuthenticatedParam,
        isExternalLoginEnabled,
        isLoading,
        logOut,
        login,
        provider,
        setError,
        settings.loginExclusivelyAzure,
        settings.signInUrl,
        settings.signOutUrl,
        t,
        urlParams
    ]);

    const value = useMemo<ExternalLoginProviderContextType>(
        () => ({
            isExternalLoginEnabled,
            isLoading,
            login,
            logOut,
            provider
        }),
        [isExternalLoginEnabled, isLoading, logOut, login, provider]
    );

    if (isExternalLoginEnabled && isLoading) {
        return <LoadingScreen />;
    }

    return (
        <ExternalLoginProviderContext.Provider value={value}>
            {children}
        </ExternalLoginProviderContext.Provider>
    );
}
