import { DimensionInputRules } from 'features/misc/dimensionInput';
import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { DimensionInputName } from 'models/TimeRegistrationModels';
import { useEffect, useState } from 'react';
import customValidators from 'utils/customValidators';
import { keyByAndFill } from 'utils/object';
import { object, string, boolean } from 'zod';
import { pick } from 'lodash';
import {
    getDimensionInputNameForAbsenceChangeRequestRegistration,
    pickDimensionPropsForInput
} from 'utils/dimension';
import { i18n } from 'appI18n';
import { Absence } from 'models/Absence';
import {
    AbsenceChangeRequestFormData,
    AbsenceRequestPostData,
    DimensionValidationRules
} from './useAbsenceChangeRequestForm.types';
import { PostAbsenceRequestPayload } from '../../AbsenceRequestRegistration/hooks/useAbsenceRequestForm';

export function getDimensionValidationRules(
    dimensionInputRules: DimensionInputRules
): DimensionValidationRules {
    const dimensionInputNamesRequired = dimensionInputRules
        .filter((rule) => rule.isRequired)
        .map((rule) => getDimensionInputNameForAbsenceChangeRequestRegistration(rule.name));
    const dimensionInputNamesOptional = dimensionInputRules
        .filter((rule) => !rule.isRequired)
        .map((rule) => getDimensionInputNameForAbsenceChangeRequestRegistration(rule.name));

    // Hva gjelder tekst; kan fremprovoseres ved å fjerne påkrevd-property på select dropdown. Den vil kunne dukke opp dersom det er en option med tom verdi - som er greit å ha tatt høyde for.
    const rulesRequired = keyByAndFill(
        dimensionInputNamesRequired,
        string().min(1, i18n.t('pleaseSelect') || undefined) // TODO:: i18n.t _should_ be accessed be passed in from context to be sure it works properly.
    );
    const rulesOptional = keyByAndFill(dimensionInputNamesOptional, string());

    return {
        ...rulesRequired,
        ...rulesOptional
    };
}

function getSchema(isShortTermAbsence: boolean, dimensionInputRules: DimensionInputRules) {
    const dimensionValidationRules = getDimensionValidationRules(dimensionInputRules);

    return isShortTermAbsence
        ? object({
              absenceId: string().min(1),
              absenceCode: string().min(1, i18n.t('pleaseSelect') || undefined),
              fromDate: customValidators.date(),
              text: string().max(
                  500,
                  i18n.t('genericValidation.errors.string.maxLength', { maxLength: 500 }) || ''
              ),
              isShortTermAbsence: boolean(),
              fromTime: customValidators.time(),
              toTime: customValidators.time(),
              ...dimensionValidationRules
          })
        : object({
              absenceId: string().min(1),
              absenceCode: string().min(1, i18n.t('pleaseSelect') || undefined),
              fromDate: customValidators.date(),
              text: string().max(
                  500,
                  i18n.t('genericValidation.errors.string.maxLength', { maxLength: 500 }) || ''
              ),
              isShortTermAbsence: boolean(),
              toDate: customValidators.date(),
              ...dimensionValidationRules
          }).superRefine(({ fromDate, toDate }, ctx) => {
              if (toDate < fromDate) {
                  ctx.addIssue({
                      code: 'custom',
                      message: i18n.t(
                          'absenceRequestRegistration.errors.cannotBeBeforeFromDate'
                      ) as string,
                      path: ['toDate']
                  });
              }
          });
}

function convertFormDataToPostData(data: AbsenceChangeRequestFormData): AbsenceRequestPostData {
    return data.isShortTermAbsence
        ? {
              ...data,
              toDate: data.fromDate
          }
        : {
              ...data,
              fromTime: '',
              toTime: ''
          };
}

type DimensionInputValues = Partial<Record<DimensionInputName, string>>;
export function getDimensionDefaultValues(
    dimensionInputRules: DimensionInputRules,
    absence: Absence
): DimensionInputValues {
    const dimensionNamesToInclude = dimensionInputRules.map((rule) => rule.name);

    // Empty values
    const dimensionInputNames = dimensionNamesToInclude.map((dimensionName) =>
        getDimensionInputNameForAbsenceChangeRequestRegistration(dimensionName)
    );
    const emptyValues = keyByAndFill(dimensionInputNames, ''); // keyByAndFill is not quite rightly typed. In this case the result is Partial.

    // Values from absence request
    const relevantDimensionPropNames = dimensionNamesToInclude.map(
        (dimensionName) => `${dimensionName}Id`
    );

    const allOriginalDimensionPropsFromObject = pickDimensionPropsForInput(absence);
    const relevantOriginalDimensionPropsFromObject = pick(
        allOriginalDimensionPropsFromObject,
        relevantDimensionPropNames
    );

    return {
        ...emptyValues,
        ...relevantOriginalDimensionPropsFromObject
    };
}
function getDefaultValues(
    isShortTermAbsence: boolean,
    dimensionInputRules: DimensionInputRules,
    absence: Absence
): AbsenceChangeRequestFormData {
    const dimensionDefaultValuesForCreate = getDimensionDefaultValues(dimensionInputRules, absence);

    return {
        absenceId: String(absence.id),
        absenceCode: absence.absenceCode || '',
        isShortTermAbsence,
        fromDate: absence.fromDate,
        toDate: absence.toDate,
        fromTime: absence.fromTime || '',
        toTime: absence.toTime || absence.toTime || '',
        text: absence.notes || '',
        ...dimensionDefaultValuesForCreate
    };
}

export default function useAbsenceRequestForm(
    dimensionInputRules: DimensionInputRules | null,
    absence: Absence,
    onSuccess?: VoidFunction
) {
    const isShortTermAbsenceAtInit = Boolean(absence.fromTime);
    const [isShortTermAbsence, setIsShortTermAbsence] = useState(isShortTermAbsenceAtInit);

    const schemaData = getSchema(isShortTermAbsence, dimensionInputRules || []);
    const defaultValues = getDefaultValues(
        isShortTermAbsenceAtInit,
        dimensionInputRules || [],
        absence
    );

    const formSetup = useFormWithApiIntegration<PostAbsenceRequestPayload>(
        'createAbsenceRequest',
        schemaData,
        defaultValues,
        {
            onSuccess,
            transformValidatedDataBeforeSend: convertFormDataToPostData
        }
    );

    const changedIsShortTermAbsence = formSetup.watch('isShortTermAbsence');
    useEffect(() => {
        if (changedIsShortTermAbsence !== isShortTermAbsence) {
            setIsShortTermAbsence(changedIsShortTermAbsence);
        }
    }, [changedIsShortTermAbsence, isShortTermAbsence, setIsShortTermAbsence]);

    return { ...formSetup, defaultValues };
}
