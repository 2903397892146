// TODO:: Get this up and running. Right now, any form goes. We'd like to restrict to types in comment.

import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { isFieldNamePresent } from 'utils/form';
import { dateToIsoString, isIsoDateString, isTimeString } from 'utils/datetime';
import dateI18n from 'appI18n/dateI18n';

type GetSuggestedOutDateFromInputProps = {
    timeIn: string;
    dateIn: string;
    timeOut: string;
};
function getSuggestedOutDateFromInput({
    timeIn,
    dateIn,
    timeOut
}: GetSuggestedOutDateFromInputProps) {
    if (isTimeString(timeIn) && isTimeString(timeOut) && isIsoDateString(dateIn)) {
        const outSameDay = timeIn < timeOut;
        return outSameDay ? dateIn : dateToIsoString(dateI18n(dateIn).add(1, 'day').toDate());
    }

    if (isUndefined(timeIn) || isUndefined(timeOut) || isUndefined(dateIn)) {
        return undefined;
    }

    return '';
}

/*
type InputForDateOutDependenciesOnCreate = {
    timeIn: string;
    dateIn: string;
    timeOut: string;
    dateOut: string;
};
type InputForDateOutDependenciesOnUpdate = {
    newTimeIn: string;
    newDateIn: string;
    newTimeOut: string;
    newDateOut: string;
};

export function useDateOutBinder(
    formProps: UseFormReturn<
        InputForDateOutDependenciesOnCreate | InputForDateOutDependenciesOnUpdate
    >
)
*/
export default function useDateOutBinder(formProps: UseFormReturn) {
    const isEdit = isFieldNamePresent('newTimeIn', formProps);

    // TODO:: Centralize input definitions
    const inputName = {
        timeIn: isEdit ? 'newTimeIn' : 'timeIn',
        dateIn: isEdit ? 'newDateIn' : 'dateIn',
        timeOut: isEdit ? 'newTimeOut' : 'timeOut',
        dateOut: isEdit ? 'newDateOut' : 'dateOut'
    } as const;

    const { watch, setValue, getValues } = formProps;

    const [timeIn, dateIn, timeOut] = watch([
        inputName.timeIn,
        inputName.dateIn,
        inputName.timeOut
    ]);
    const currentDateOut = getValues(inputName.dateOut);

    useEffect(() => {
        const newDateOut = getSuggestedOutDateFromInput({ timeIn, dateIn, timeOut });
        if (!isUndefined(newDateOut) && newDateOut !== currentDateOut) {
            setValue(inputName.dateOut, newDateOut);
        }
    }, [timeIn, dateIn, timeOut, currentDateOut, setValue, inputName.dateOut]);
}
