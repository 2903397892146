import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import useStickyBelowAppBarSx from 'hooks/useStickyBelowAppBarSx';
import { ExpandMore } from '@mui/icons-material';
import { SyntheticEvent, useEffect } from 'react';
import { useFetch } from 'hooks/useFetch';
import ErrorMessage from 'components/error/ErrorMessage';
import { useTranslation } from 'react-i18next';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import { GetReportPayload, ReportHeader } from './Reports.types';

type ReportAccordionProps = {
    reportHeader: ReportHeader;
    isExpanded: boolean;
    onChangeCallback: (event: SyntheticEvent, expanded: boolean) => void;
};

export default function ReportAccordion({
    reportHeader: report,
    isExpanded,
    onChangeCallback
}: ReportAccordionProps) {
    const [data, { isLoading, errorMessage, sendRequest }] = useFetch<GetReportPayload>(
        'getReport',
        {
            reqData: { reportId: report.id },
            manuallyTriggerRequest: true
        }
    );

    useEffect(() => {
        if (isExpanded && !data && !isLoading && !errorMessage) {
            sendRequest();
        }
    }, [isExpanded, sendRequest, data, isLoading, errorMessage]);

    const { t } = useTranslation();

    const stickyProps = useStickyBelowAppBarSx();

    return (
        <Accordion
            expanded={isExpanded}
            onChange={onChangeCallback}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${report.id}-content`}
                id={`panel${report.id}-header`}
                sx={(theme) => ({
                    ...stickyProps,
                    backgroundColor: theme.palette.background.paper
                })}
            >
                <Typography>
                    {report.name === ''
                        ? t('reports.defaultName', { number: report.id })
                        : report.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{report.comment}</Typography>
                {!isLoading &&
                    data &&
                    (data.hasResults ? (
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: data.resultHtml }}
                            style={{ overflowX: 'auto' }}
                        />
                    ) : (
                        <Typography>{t('reports.emptyDataSet')}</Typography>
                    ))}
                {isLoading && <InlineLoadingSpinner />}
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </AccordionDetails>
        </Accordion>
    );
}
