/* eslint-disable import/prefer-default-export */
import {
    getPreviouslySelectedClientIdCookie,
    getLegacyPreviouslySelectedClientIdCookie,
    setPreviouslySelectedClientIdCookie
} from 'utils/cookie';

export function loginAzureAd(signInUrl: string) {
    const currentUrl = window.location.origin + window.location.pathname;
    const signInUrlWithReturnUrl = `${signInUrl}?returnUrl=${encodeURIComponent(currentUrl)}`;
    let clientId = getPreviouslySelectedClientIdCookie();

    if (!clientId) {
        clientId = getLegacyPreviouslySelectedClientIdCookie();
    }

    if (clientId && Number(clientId) > 0) {
        window.location.href = `${signInUrlWithReturnUrl}&capitechclientid=${clientId}`;
    } else {
        window.location.href = signInUrlWithReturnUrl;
    }
}

export function logoutAzureAd() {
    const redirTo = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    setPreviouslySelectedClientIdCookie('');
    window.location.replace(redirTo);
}
