import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { t } from 'i18next';
import dateI18n, { FIXED_LOCALIZED_DATE_FORMAT } from 'appI18n/dateI18n';
import ErrorMessage from 'components/error/ErrorMessage';
import { useRef } from 'react';
import useSizeMaintainer from 'hooks/useSizeMaintainer';
import { FlexiTimeBalance } from '../Balances.types';

type FlexiBalanceTableViewProps = {
    isLoading: boolean;
    isDense?: boolean;
    errorMessage: string | null;
    flexiTimeBalances: Array<FlexiTimeBalance> | null;
};

export default function FlexiBalanceTableView({
    errorMessage,
    flexiTimeBalances,
    isDense,
    isLoading
}: FlexiBalanceTableViewProps) {
    const flexiBalanceTableRef = useRef(null);
    const sizeFlexiBalanceCardIfLoading = useSizeMaintainer(flexiBalanceTableRef, isLoading, true);

    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {!errorMessage && flexiTimeBalances && (
                <TableContainer
                    component={Paper}
                    ref={flexiBalanceTableRef}
                    sx={sizeFlexiBalanceCardIfLoading}
                >
                    <Table size="small" aria-label={t('balances.flexitime.summaryTable.label')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('balances.flexitime.summaryTable.periodTitle')}
                                </TableCell>
                                {!isDense && (
                                    <TableCell>
                                        {t('balances.flexitime.summaryTable.fromDate')}
                                    </TableCell>
                                )}
                                {!isDense && (
                                    <TableCell>
                                        {t('balances.flexitime.summaryTable.toDate')}
                                    </TableCell>
                                )}
                                <TableCell>
                                    {t('balances.flexitime.summaryTable.description')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('balances.flexitime.summaryTable.hours')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isLoading && flexiTimeBalances.length === 0 && (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" colSpan={isDense ? 3 : 5} scope="row">
                                        {t('balances.noData')}
                                    </TableCell>
                                    <TableCell align="right" />
                                </TableRow>
                            )}
                            {!isLoading &&
                                flexiTimeBalances.map((flexiTimeBalance) => (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        key={`flexacc${flexiTimeBalance.accountId}`}
                                    >
                                        <TableCell component="th" scope="row">
                                            {flexiTimeBalance.periodDescription}
                                        </TableCell>
                                        {!isDense && (
                                            <TableCell>
                                                {dateI18n(flexiTimeBalance.fromDate).format(
                                                    FIXED_LOCALIZED_DATE_FORMAT
                                                )}
                                            </TableCell>
                                        )}
                                        {!isDense && (
                                            <TableCell>
                                                {dateI18n(flexiTimeBalance.toDate).format(
                                                    FIXED_LOCALIZED_DATE_FORMAT
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell>{flexiTimeBalance.accountDescription}</TableCell>
                                        <TableCell align="right">
                                            {flexiTimeBalance.totalBalanceInHours.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}

FlexiBalanceTableView.defaultProps = {
    isDense: false
};
