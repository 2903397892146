import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';
import { useDialog } from 'ctx/DialogCtx';
import { useEffect } from 'react';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import useLoadingScreen from 'hooks/useLoadingScreen';
import { Feature } from 'features/misc/employeeSettings/hooks/useFeatureAccess/useFeatureAccess.types';

type RequireFeatureRouteProps = {
    featureName: Feature | Array<Feature>;
};

/**
 * Component that conditionally renders its children only if the user has access to any one, or all, of the specified feature(s).
 * If the user does not have access, they are redirected to the home page.
 *
 * @param featureName - The name of the feature(s) to check access for.
 * @returns The component to render if the user has access, otherwise the user is redirected to the home page.
 */
export default function RequireFeatureRoute({ featureName }: RequireFeatureRouteProps) {
    const { access, isLoading } = useFeatureAccess();
    let accessUnknown: boolean;
    let accessDenied: boolean;

    if (Array.isArray(featureName)) {
        accessUnknown = featureName.some((feature) => access[feature] === null);
        accessDenied = !featureName.some((feature) => access[feature] === true);
    } else {
        accessUnknown = access[featureName] === null;
        accessDenied = access[featureName] === false;
    }

    const isIndeterminate = accessUnknown || (accessDenied && isLoading); // unavailable && loading should be given the benifit of doubt

    const { setSourceIsLoading } = useLoadingScreen();
    const location = useLocation();
    const { clearDialogs } = useDialog();

    useEffect(() => {
        if (accessDenied) {
            clearDialogs();
        }
    }, [accessDenied, clearDialogs]);

    // Loading screen if indeterminate
    useEffect(() => {
        setSourceIsLoading(`loading-feature-${featureName}-access`, isIndeterminate);
    }, [featureName, isIndeterminate, setSourceIsLoading]);
    if (isIndeterminate) {
        return null;
    }

    if (accessDenied) {
        return <Navigate to={PageRoutes.Home} state={{ from: location }} replace />;
    }

    return <Outlet />;
}
