import { useDimensionOptionsSet } from 'features/misc/dimensionInput';
import { useMemo, useState } from 'react';
import AlertDialog from 'components/dialog/AlertDialog';
import { useTranslation } from 'react-i18next';
import useAbsenceChangeRequestForm from './hooks/useAbsenceChangeRequestForm';
import { useAbsenceDimensionInputRules, useAbsenceCodeOptions } from '../hooks';
import { pickDimensionValuesFromDefaultValues } from './AbsenceChangeRequestRegistration.helpers';
import { AbsenceChangeRequestRegistrationProps } from './AbsenceChangeRequestRegistration.types';
import AbsenceChangeRequestRegistrationView from './AbsenceChangeRequestRegistrationView';

export default function AbsenceChangeRequestRegistration({
    onCancel,
    onSuccess,
    absence,
    sx
}: AbsenceChangeRequestRegistrationProps) {
    const { dimensionInputRules } = useAbsenceDimensionInputRules(absence.fromDate, absence);

    const { defaultValues, ...formProps } = useAbsenceChangeRequestForm(
        dimensionInputRules,
        absence,
        onSuccess
    );

    const initDimensionValuesByDimensionName = useMemo(() => {
        const dimensionValuesFromDefault = pickDimensionValuesFromDefaultValues(defaultValues);
        return dimensionValuesFromDefault;
    }, [defaultValues]);

    const dimensionValuesIgnoringValueChangesAfterInit = initDimensionValuesByDimensionName; // Providing up-to-date values are only necessary when there's a possibility for dependecies across dimensions (ie Project<-->SubProject). For now we know this is not the case and we don't set aside time for keeping the value up to date.
    const { dimensionOptionsSet, isDescriptionsInitialized, dimensionNamesLoading } =
        useDimensionOptionsSet(
            dimensionInputRules,
            dimensionValuesIgnoringValueChangesAfterInit,
            initDimensionValuesByDimensionName
        );

    const {
        options: absenceCodeOptions,
        isLoading: isLoadingAbsenceCodeOptions,
        errorMessage
    } = useAbsenceCodeOptions();

    const dimInputRules = useMemo(() => dimensionInputRules || [], [dimensionInputRules]);

    const { t } = useTranslation();
    const [hasError, setHasError] = useState(errorMessage !== undefined);

    if (errorMessage && !isLoadingAbsenceCodeOptions) {
        return (
            <AlertDialog
                errorMessage={errorMessage}
                onConfirm={() => {
                    setHasError(false);
                    onCancel(); // Close the dialog opened by the parent
                }}
                title={t('error')}
                open={hasError}
                isRunningConfirmOperation={false}
                severity="error"
            />
        );
    }

    return (
        <AbsenceChangeRequestRegistrationView
            isLoading={isLoadingAbsenceCodeOptions || !isDescriptionsInitialized}
            formProps={formProps}
            onCancel={onCancel}
            sx={sx}
            absenceCodeOptions={absenceCodeOptions}
            isLoadingAbsenceCodeOptions={isLoadingAbsenceCodeOptions}
            dimensionInputRules={dimInputRules}
            dimensionOptionsSet={dimensionOptionsSet}
            dimensionNamesLoading={dimensionNamesLoading}
            initDimensionValuesByDimensionName={initDimensionValuesByDimensionName}
        />
    );
}

AbsenceChangeRequestRegistration.defaultProps = {
    sx: undefined
};
