import { dateToIsoString } from 'utils/datetime';
import {
    useEmployeeRegistrationInformation,
    useFeatureAccess
} from 'features/misc/employeeSettings';
import { useMemo } from 'react';
import { UseBalanceNamesAvailableResult } from './useBalanceNamesAvailable.types';

export default function useBalanceNamesAvailable(): UseBalanceNamesAvailableResult {
    const todayStr = dateToIsoString(new Date());

    const { employeeRegistrationInformation, isLoading: isLoadingEmployeeRegistrationInformation } =
        useEmployeeRegistrationInformation(todayStr);

    const {
        access: featureAccess,
        isLoading: isLoadingFeatureAccess,
        isLoadingFirstTime: isLoadingFeatureAccessFirstTime
    } = useFeatureAccess();

    return useMemo((): UseBalanceNamesAvailableResult => {
        const isLoadingEmployeeRegistrationInformationFirstTime =
            isLoadingEmployeeRegistrationInformation && employeeRegistrationInformation === null;

        const isLoadingFirstTime =
            isLoadingEmployeeRegistrationInformationFirstTime || isLoadingFeatureAccessFirstTime;

        const hasAbsence =
            featureAccess.simployerAbsence === false && featureAccess.absence === true;

        const balanceNamesAvailable: UseBalanceNamesAvailableResult['balanceNamesAvailable'] =
            isLoadingFirstTime
                ? undefined
                : [
                      ...(employeeRegistrationInformation?.hasFlexitime
                          ? (['flexitime'] as const)
                          : []),
                      ...(hasAbsence ? (['vacation'] as const) : [])
                  ];

        return {
            isLoading: isLoadingEmployeeRegistrationInformation || isLoadingFeatureAccess,
            isLoadingFirstTime,
            balanceNamesAvailable
        };
    }, [
        isLoadingEmployeeRegistrationInformation,
        employeeRegistrationInformation,
        isLoadingFeatureAccessFirstTime,
        featureAccess.simployerAbsence,
        featureAccess.absence,
        isLoadingFeatureAccess
    ]);
}
