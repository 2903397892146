import { useFetch, useFetchArray } from 'hooks/useFetch';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ErrorMessage from 'components/error/ErrorMessage';
import { useTranslation } from 'react-i18next';
import dateI18n, { FIXED_LOCALIZED_DATE_FORMAT } from 'appI18n/dateI18n';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import { VacationBalance, VacationTransactions } from '../Balances.types';
import VacationBalanceTableView from './VacationBalanceTableView';

export default function VacationBalanceTab() {
    const { t } = useTranslation();
    const [vacationBalance, { isLoading: isLoadingBalance, errorMessage: errorMessageBalance }] =
        useFetch<VacationBalance>('getVacationBalance');
    const [
        vacationTransactions,
        { isLoading: isLoadingTransactions, errorMessage: errorMessageTransactions }
    ] = useFetchArray<VacationTransactions>('getVacationTransactions');
    const isLoading = isLoadingBalance || isLoadingTransactions;

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    return (
        <>
            <Typography
                component="h1"
                variant="h5"
                gutterBottom
                sx={{
                    textTransform: 'uppercase'
                }}
            >
                {t('balances.vacation.pageTitle')}
            </Typography>
            <Typography variant="h6" component="h2">
                {t('balances.vacation.summaryTitle')}
            </Typography>
            <VacationBalanceTableView
                vacationBalance={vacationBalance || null}
                isLoading={isLoadingBalance}
                errorMessage={errorMessageBalance}
            />
            <Typography variant="h6" component="h2" mt={2}>
                {t('balances.vacation.transactionsTitle')}
            </Typography>
            {errorMessageTransactions && <ErrorMessage message={errorMessageTransactions} />}
            {!errorMessageTransactions && vacationTransactions.length > 0 && (
                <TableContainer component={Paper}>
                    <Table size="small" aria-label={t('balances.vacation.transactionsTable.label')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {t('balances.vacation.transactionsTable.weekNo')}
                                </TableCell>
                                <TableCell>
                                    {t('balances.vacation.transactionsTable.day')}
                                </TableCell>
                                <TableCell>
                                    {t('balances.vacation.transactionsTable.date')}
                                </TableCell>
                                <TableCell>
                                    {t('balances.vacation.transactionsTable.statusHeader')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vacationTransactions.length === 0 && (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell colSpan={4}>{t('balances.noData')}</TableCell>
                                </TableRow>
                            )}
                            {vacationTransactions.map((row) => {
                                const date = dateI18n(row.date);
                                const isInThePast = date.isBefore(dateI18n());
                                const status = isInThePast
                                    ? t('balances.vacation.transactionsTable.status.completed')
                                    : t('balances.vacation.transactionsTable.status.upcoming');

                                return (
                                    <TableRow
                                        key={date.unix()}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{date.week()}</TableCell>
                                        <TableCell>{date.format('dddd')}</TableCell>
                                        <TableCell>
                                            {date.format(FIXED_LOCALIZED_DATE_FORMAT)}
                                        </TableCell>
                                        <TableCell>{status}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
