import useErrorScreen from 'hooks/useErrorScreen';
import { useFetchOutsideAuthContext } from 'hooks/useFetchOutsideAuthContext';
import useLoadingScreen from 'hooks/useLoadingScreen';
import { createContext, PropsWithChildren, useEffect } from 'react';

export type AppSettings = {
    signInUrl: string;
    signOutUrl: string;
    loginSimployerAuth0: boolean;
    loginUserNameAndPassword: boolean;
    theme: string;
    showForgotPassword?: boolean;
    clientSelectionAftherLogin: boolean;
    loginAzure: boolean;
    loginExclusivelyAzure: boolean;
    loginSimployerSaam: boolean;
    showChangePassword: boolean;
    simployerAuthority: null | string;
    simployerGlobalMenuCssUrl: string;
    simployerGlobalMenuJsUrl: string;
    simployerGlobalMenuJsUrlAuth0: string;
};

export const AppSettingsContext = createContext<AppSettings>(null!);

export function AppSettingsProvider({ children }: PropsWithChildren) {
    const [settings, { isLoading, errorMessage }] = useFetchOutsideAuthContext<AppSettings>(
        'getSettings',
        {
            isAnonymous: true,
            isGet: true
        }
    );
    const { setSourceIsLoading } = useLoadingScreen();
    const { setError } = useErrorScreen();

    useEffect(() => {
        setSourceIsLoading('AppSettingsContext', isLoading);
    }, [isLoading, setSourceIsLoading]);

    useEffect(() => {
        if (errorMessage) {
            setError({ message: 'Failed loading app settings.' });
        }
    }, [errorMessage, setError]);

    // Rest of component tree is on hold while client list is visible
    if (isLoading || errorMessage) {
        return null;
    }

    return <AppSettingsContext.Provider value={settings}>{children}</AppSettingsContext.Provider>;
}
