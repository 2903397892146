import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import getMuiTranslations from 'appI18n/getMuiTranslations';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import getBasename from 'router/utils/getBasename';
import themeOptions from 'theme';
import { DEFAULT_LANGUAGE, isSupportedLanguage } from 'appI18n';
import { AppSettingsProvider } from './AppSettingsProvider';
import { AuthProvider } from './AuthProvider';
import { ClientListProvider } from './ClientListProvider';
import DialogProvider from './DialogCtx';
import ErrorScreenProvider from './ErrorScreenProvider';
import LoadingScreenProvider from './LoadingScreenProvider';
import { AuthorizedClientListProvider } from './AuthorizedClientListProvider';
import { ExternalLoginProvider } from './ExternalLoginProvider';
import ContentKillSwitchProvider from './ContentKillSwitchProvider/ContentKillSwitchProvider';

export default function AppProviders({ children }: PropsWithChildren) {
    const { i18n } = useTranslation();

    // Will create theme on each render, but seems unlikely there'll be that many
    const supportedLanguage = isSupportedLanguage(i18n.language) ? i18n.language : DEFAULT_LANGUAGE;
    const muiTranslations = getMuiTranslations(supportedLanguage);
    const customTheme = createTheme(themeOptions, ...muiTranslations);
    const basename = getBasename(window.location.pathname);

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <ErrorScreenProvider>
                <LoadingScreenProvider>
                    <DialogProvider>
                        <AppSettingsProvider>
                            <BrowserRouter basename={basename}>
                                <ContentKillSwitchProvider>
                                    <ExternalLoginProvider>
                                        <ClientListProvider>
                                            <AuthProvider>
                                                <AuthorizedClientListProvider>
                                                    {children}
                                                </AuthorizedClientListProvider>
                                            </AuthProvider>
                                        </ClientListProvider>
                                    </ExternalLoginProvider>
                                </ContentKillSwitchProvider>
                            </BrowserRouter>
                        </AppSettingsProvider>
                    </DialogProvider>
                </LoadingScreenProvider>
            </ErrorScreenProvider>
        </ThemeProvider>
    );
}
