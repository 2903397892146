import useAppSettings from 'hooks/useAppSettings';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';
import useLoginForm from './hooks/useLoginForm';
import LoginView from './LoginView';

export default function Login() {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const settings = useAppSettings();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(PageRoutes.Home);
        }
    });

    const formProps = useLoginForm();

    return (
        <LoginView
            formProps={formProps}
            showUserNameAndPassword={!settings.loginExclusivelyAzure}
            showForgotPasswordLink={settings.showForgotPassword}
            showAzureADLogin={settings.loginAzure || settings.loginExclusivelyAzure}
            formWidth={settings.loginExclusivelyAzure ? 'xs' : 'sm'}
        />
    );
}
