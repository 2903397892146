import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { object, string } from 'zod';
import { i18n } from 'appI18n';

type StartDialogResponse = {
    threadId: string;
};

export default function useStartDialogRequestForm(onSuccess?: (dialogId: string) => void) {
    const formTarget = 'startDialog';

    const schemaData = object({
        receivingDepartmentId: string().min(1, i18n.t('pleaseSelect') || undefined),
        subject: string().min(1, i18n.t('pleaseFill') || undefined),
        body: string()
    });

    const formSetup = useFormWithApiIntegration<StartDialogResponse>(
        formTarget,
        schemaData,
        {
            receivingDepartmentId: '',
            subject: '',
            body: ''
        },
        {
            onSuccess: (data) => {
                if (onSuccess) {
                    onSuccess(data.threadId);
                }
            }
        }
    );

    return { ...formSetup };
}
