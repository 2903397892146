import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PasswordPolicy } from 'models/MyCapitechClientSettings';
import PasswordChangeForm from './PasswordChangeForm';

type PasswordChangeCardProps = {
    passwordPolicy: PasswordPolicy;
};

export default function PasswordChangeCard({ passwordPolicy }: PasswordChangeCardProps) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent sx={{ pb: 0 }}>
                <Typography component="h2" variant="h5" gutterBottom>
                    {t('profile.passwordChange.passwordChange')}
                </Typography>
                <PasswordChangeForm passwordPolicy={passwordPolicy} />
            </CardContent>
        </Card>
    );
}
