import { Stack } from '@mui/material';
import useAppSettings from 'hooks/useAppSettings';
import useMyCapitechClientSettings from 'hooks/useMyCapitechClientSettings';
import { ProfileDetails } from '../../details';
import { ProfileSettings } from '../../settings';
import { PasswordChangeCard } from '../../password';

export default function ProfileMain() {
    const { showChangePassword } = useAppSettings();

    const { myCapitechClientSettings } = useMyCapitechClientSettings();
    const passwordPolicy = myCapitechClientSettings?.passwordPolicy
        ? {
              ...myCapitechClientSettings.passwordPolicy,
              notJustWhiteSpace: true
          }
        : null;

    return (
        <Stack spacing={4} mt={2}>
            <ProfileDetails />
            <ProfileSettings />
            {showChangePassword && passwordPolicy && (
                <PasswordChangeCard passwordPolicy={passwordPolicy} />
            )}
        </Stack>
    );
}
