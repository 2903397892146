import { PasswordPolicy } from 'models/MyCapitechClientSettings';
import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import { object, string, z } from 'zod';
import runPasswordPolicyCheck from '../runPasswordPolicyCheck';

type UsePasswordChangeFormProps = {
    onBeforeHandleSubmit: () => boolean;
    passwordPolicy: PasswordPolicy;
};

export default function usePasswordChangeForm({
    onBeforeHandleSubmit,
    passwordPolicy
}: UsePasswordChangeFormProps) {
    const { t } = useTranslation();

    const formTarget = 'changePassword';
    const passwordChangeSchema = object({
        // Have a look at first commit ¡after! 942de291 if oldPassword or newPassword is to be added to the schema, as there are some easy forgettable neuances to it
        newPassword: string().nonempty(t('pleaseFill') || undefined)
    }).superRefine(({ newPassword }, ctx) => {
        const policyCheckResult = runPasswordPolicyCheck(newPassword, passwordPolicy);

        if (policyCheckResult.notJustWhiteSpace === false) {
            ctx.addIssue({
                code: 'custom',
                message: t('profile.passwordChange.policyValidation.notJustWhiteSpace'),
                path: ['newPassword']
            });
            return;
        }

        if (policyCheckResult.maximumLength === false) {
            ctx.addIssue({
                code: 'custom',
                message: t('numericString.errors.breaksMax', { max: passwordPolicy.maximumLength }),
                path: ['newPassword']
            });
            return;
        }

        const isSatifyingPasswordPolicy =
            Object.values(policyCheckResult).filter((value) => !value).length === 0;

        if (!isSatifyingPasswordPolicy) {
            ctx.addIssue({
                code: 'custom',
                message: t('profile.passwordChange.passwordNotSafeEnough'),
                path: ['newPassword']
            });
        }
    });

    type PasswordChangeRequest = z.infer<typeof passwordChangeSchema>;

    const formProps = useFormWithApiIntegration<PasswordChangeRequest>(
        formTarget,
        passwordChangeSchema,
        {
            newPassword: ''
        },
        {
            onBeforeHandleSubmit
        }
    );
    return formProps;
}
