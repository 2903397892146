import { PasswordPolicy } from 'models/MyCapitechClientSettings';
import { Box, Chip } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import runPasswordPolicyCheck from './runPasswordPolicyCheck';

type PasswordPolicyProgressProps = {
    password: string;
    passwordPolicy: PasswordPolicy;
    highlightUnmetPolicies: boolean;
};

export default function PasswordPolicyProgress({
    password,
    passwordPolicy,
    highlightUnmetPolicies
}: PasswordPolicyProgressProps) {
    const { t } = useTranslation();
    const policyCheckResult = runPasswordPolicyCheck(password, passwordPolicy);

    // Applies any numeric value as 'amount' to the translation string
    const getPolicyLabel = (policyName: keyof PasswordPolicy) => {
        const policyValue = passwordPolicy[policyName];
        const isNumericValue = typeof +policyValue === 'number' && !Number.isNaN(+policyValue);
        const useableCountData = isNumericValue ? { amount: String(policyValue) } : undefined;
        return t(`profile.passwordChange.policyValidation.${policyName}`, useableCountData);
    };

    const intrusiveHiddenPolicyParts: Array<keyof PasswordPolicy> = ['maximumLength'];
    const hiddenPolicyParts: Array<keyof PasswordPolicy> = [
        ...intrusiveHiddenPolicyParts,
        'notJustWhiteSpace'
    ];

    const getProgressIcon = (policyName: keyof PasswordPolicy, isPolicyMet: boolean) => {
        if (isPolicyMet) {
            return <Check />;
        }
        if (highlightUnmetPolicies) {
            return <Close />;
        }
        if (intrusiveHiddenPolicyParts.includes(policyName) && !isPolicyMet) {
            return <Close />;
        }
        return undefined;
    };

    const getPolicyColor = (policyName: keyof PasswordPolicy, isPolicyMet: boolean) => {
        if (isPolicyMet) {
            return 'success';
        }
        if (highlightUnmetPolicies) {
            return 'error';
        }
        if (intrusiveHiddenPolicyParts.includes(policyName) && !isPolicyMet) {
            return 'error';
        }

        return 'default';
    };

    const pxBetweenHelperTextAndInput = '8px';
    const pxHalfIconWidth = '12px';

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: pxBetweenHelperTextAndInput }}>
            {Object.entries(policyCheckResult)
                .filter(
                    ([policyName, isPolicyMet]) =>
                        !hiddenPolicyParts.includes(policyName as keyof PasswordPolicy) || // Includes non-hidden policy
                        (!isPolicyMet &&
                            intrusiveHiddenPolicyParts.includes(
                                policyName as keyof PasswordPolicy
                            )) || // Includes hidden policy which should be intrusive
                        (!isPolicyMet && highlightUnmetPolicies) // Includes hidden policy when appropriate
                )
                .map(([policyName, isPolicyMet]) => (
                    <Chip
                        sx={{
                            px: getProgressIcon(policyName as keyof PasswordPolicy, isPolicyMet)
                                ? '0px;'
                                : pxHalfIconWidth
                        }}
                        key={policyName}
                        label={getPolicyLabel(policyName as keyof PasswordPolicy)}
                        icon={getProgressIcon(policyName as keyof PasswordPolicy, isPolicyMet)}
                        color={getPolicyColor(policyName as keyof PasswordPolicy, isPolicyMet)}
                    />
                ))}
        </Box>
    );
}
