import { i18n } from 'appI18n';

export function getTranslatedOrFallback(possiblyPrefixedKey: string, fallback?: string): string {
    const possibleKeyPrefix = 'LocalizationKey: ';

    const key = possiblyPrefixedKey.startsWith(possibleKeyPrefix)
        ? possiblyPrefixedKey.substring(possibleKeyPrefix.length)
        : possiblyPrefixedKey;

    if (i18n.exists(key)) {
        return i18n.t(key);
    }

    const serverErrorKey = `serverErrors.${key}`;
    if (i18n.exists(serverErrorKey)) {
        return i18n.t(serverErrorKey);
    }

    if (fallback) {
        if (i18n.exists(fallback)) {
            return i18n.t(fallback);
        }
        return fallback;
    }

    return possiblyPrefixedKey;
}

export function getTranslatedError(keyBearer: string, fallback?: string) {
    const translationKeyIndicator = 'LocalizationKey: ';

    const errorName = keyBearer.startsWith(translationKeyIndicator)
        ? keyBearer.substring(translationKeyIndicator.length)
        : keyBearer;

    const keyPath = `serverErrors.${errorName}`;

    return getTranslatedOrFallback(keyPath, fallback);
}
