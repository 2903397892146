import dateI18n from 'appI18n/dateI18n';
import { useMemo } from 'react';
import { dateToIsoString } from 'utils/datetime';
import { UseDateStartMinAbsenceRequestsResult } from './useDateStartMinAbsenceRequests.types';

export default function useDateStartMinAbsenceRequests(): UseDateStartMinAbsenceRequestsResult {
    const dateToday = dateToIsoString(new Date());
    const minStartdate = useMemo(
        () => dateI18n(dateToday).subtract(1, 'year').toDate(),
        [dateToday]
    );

    return minStartdate;
}
