import {
    DimensionInputRuleWithDefaultValue,
    DimensionInputRules
} from 'features/misc/dimensionInput';
import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { DimensionInputName } from 'models/TimeRegistrationModels';
import { useEffect, useState } from 'react';
import customValidators from 'utils/customValidators';
import { keyByAndFill } from 'utils/object';
import { object, string, boolean, ZodString } from 'zod';
import { fromPairs, isNull, pick } from 'lodash';
import {
    getDimensionNameAsInputNameForAbsenceRegistration,
    pickDimensionPropsForInput
} from 'utils/dimension';
import { i18n } from 'appI18n';
import AbsenceRequest from 'models/AbsenceRequest';

export type PostAbsenceRequestPayload = number;

export type DimensionPostData = Partial<Record<DimensionInputName, string>>;
type DimensionValidationRules = Partial<Record<keyof DimensionPostData, ZodString>>;

interface SharedAbsenceRequestFormData extends DimensionPostData {
    absenceCode: string;
    isShortTermAbsence: boolean;
    fromDate: string;
    text: string;
    absenceRequestId?: string;
    absenceId?: string;
}

interface AbsenceFormDataShortTerm extends SharedAbsenceRequestFormData {
    isShortTermAbsence: true;
    fromTime: string;
    toTime: string;
}

interface AbsenceFormDataLongTerm extends SharedAbsenceRequestFormData {
    isShortTermAbsence: false;
    toDate: string;
}

interface AbsenceRequestPostData
    extends SharedAbsenceRequestFormData,
        Omit<AbsenceFormDataShortTerm, 'isShortTermAbsence'>,
        Omit<AbsenceFormDataLongTerm, 'isShortTermAbsence'> {}

export function getDimensionValidationRules(
    dimensionInputRules: DimensionInputRules
): DimensionValidationRules {
    const dimensionInputNamesRequired = dimensionInputRules
        .filter((rule) => rule.isRequired)
        .map((rule) => getDimensionNameAsInputNameForAbsenceRegistration(rule.name));
    const dimensionInputNamesOptional = dimensionInputRules
        .filter((rule) => !rule.isRequired)
        .map((rule) => getDimensionNameAsInputNameForAbsenceRegistration(rule.name));

    // Hva gjelder tekst; kan fremprovoseres ved å fjerne påkrevd-property på select dropdown. Den vil kunne dukke opp dersom det er en option med tom verdi - som er greit å ha tatt høyde for.
    const rulesRequired = keyByAndFill(
        dimensionInputNamesRequired,
        string().min(1, i18n.t('pleaseSelect') || undefined) // TODO:: i18n.t _should_ be accessed be passed in from context to be sure it works properly.
    );
    const rulesOptional = keyByAndFill(dimensionInputNamesOptional, string());

    return {
        ...rulesRequired,
        ...rulesOptional
    };
}

function getSchema(
    isShortTermAbsence: boolean,
    dimensionInputRules: DimensionInputRules,
    absenceRequest?: AbsenceRequest
) {
    const dimensionValidationRules = getDimensionValidationRules(dimensionInputRules);

    return isShortTermAbsence
        ? object({
              absenceCode: string().min(1, i18n.t('pleaseSelect') || undefined),
              fromDate: customValidators.date(),
              text: string().max(
                  500,
                  i18n.t('genericValidation.errors.string.maxLength', { maxLength: 500 }) || ''
              ),
              isShortTermAbsence: boolean(),
              fromTime: customValidators.time(),
              toTime: customValidators.time(),
              ...dimensionValidationRules,
              ...(absenceRequest && { absenceRequestId: string().min(1) }),
              ...(absenceRequest?.absenceId && { absenceId: string().min(1) })
          })
        : object({
              absenceCode: string().min(1, i18n.t('pleaseSelect') || undefined),
              fromDate: customValidators.date(),
              text: string().max(
                  500,
                  i18n.t('genericValidation.errors.string.maxLength', { maxLength: 500 }) || ''
              ),
              isShortTermAbsence: boolean(),
              toDate: customValidators.date(),
              ...dimensionValidationRules,
              ...(absenceRequest && { absenceRequestId: string().min(1) }),
              ...(absenceRequest?.absenceId && { absenceId: string().min(1) })
          }).superRefine(({ fromDate, toDate }, ctx) => {
              if (toDate < fromDate) {
                  ctx.addIssue({
                      code: 'custom',
                      message: i18n.t(
                          'absenceRequestRegistration.errors.cannotBeBeforeFromDate'
                      ) as string,
                      path: ['toDate']
                  });
              }
          });
}

function adjustSubmittedDataToAbsenceLength(
    data: AbsenceFormDataShortTerm | AbsenceFormDataLongTerm
): AbsenceRequestPostData {
    return data.isShortTermAbsence
        ? {
              ...data,
              toDate: data.fromDate
          }
        : {
              ...data,
              fromTime: '',
              toTime: ''
          };
}

type DimensionInputValues = Partial<Record<DimensionInputName, string>>;
export function getDimensionDefaultValues(
    dimensionInputRules: DimensionInputRules,
    absenceRequest?: AbsenceRequest
): DimensionInputValues {
    const dimensionNamesToInclude = dimensionInputRules.map((rule) => rule.name);

    // Empty values
    const dimensionInputNames = dimensionNamesToInclude.map((dimensionName) =>
        getDimensionNameAsInputNameForAbsenceRegistration(dimensionName)
    );
    const emptyValues = keyByAndFill(dimensionInputNames, ''); // keyByAndFill is not quite rightly typed. In this case the result is Partial.

    // Values from absence request
    if (absenceRequest) {
        const dimensionPropNamesRelevant = dimensionNamesToInclude.map(
            (dimensionName) => `${dimensionName}Id`
        );

        const dimensionPropsFromObject = pickDimensionPropsForInput(absenceRequest);
        const dimensionPropsFromObjectRelevant = pick(
            dimensionPropsFromObject,
            dimensionPropNamesRelevant
        );

        return {
            ...emptyValues,
            ...dimensionPropsFromObjectRelevant
        };
    }

    // Values from settings
    const rulesWithDefaultValue = dimensionInputRules.filter(
        (rule): rule is DimensionInputRuleWithDefaultValue => !isNull(rule.defaultValue)
    );
    const dimensionValuePairsFromSettings = rulesWithDefaultValue.map((rule) => [
        getDimensionNameAsInputNameForAbsenceRegistration(rule.name),
        rule.defaultValue
    ]);
    const dimensionValuesFromSettings: DimensionInputValues = fromPairs(
        dimensionValuePairsFromSettings
    );

    return {
        ...emptyValues,
        ...dimensionValuesFromSettings
    };
}
function getDefaultValues(
    dateInIsoFormat: string,
    isShortTermAbsence: boolean,
    dimensionInputRules: DimensionInputRules,
    absenceRequest?: AbsenceRequest
): AbsenceRequestPostData {
    const dimensionDefaultValuesForCreate = getDimensionDefaultValues(
        dimensionInputRules,
        absenceRequest
    );

    return {
        absenceCode: absenceRequest?.absenceCode || '',
        isShortTermAbsence,
        fromDate: absenceRequest?.fromDate || dateInIsoFormat,
        toDate: absenceRequest?.toDate || dateInIsoFormat,
        fromTime: absenceRequest?.fromTime || '',
        toTime: absenceRequest?.toTime || '',
        text: absenceRequest?.text || '',
        ...dimensionDefaultValuesForCreate,
        ...(absenceRequest && { absenceRequestId: String(absenceRequest.id) }),
        ...(absenceRequest?.absenceId && { absenceId: String(absenceRequest.absenceId) })
    };
}

export default function useAbsenceRequestForm(
    dateInIsoFormat: string,
    dimensionInputRules: DimensionInputRules | null,
    absenceRequest?: AbsenceRequest,
    onSuccess?: VoidFunction
) {
    const isShortTermAbsenceAtInit = absenceRequest ? Boolean(absenceRequest.fromTime) : false;
    const [isShortTermAbsence, setIsShortTermAbsence] = useState(isShortTermAbsenceAtInit);

    const formTarget = absenceRequest ? 'updateAbsenceRequest' : 'createAbsenceRequest';
    const schemaData = getSchema(isShortTermAbsence, dimensionInputRules || [], absenceRequest);
    const defaultValues = getDefaultValues(
        dateInIsoFormat,
        isShortTermAbsenceAtInit,
        dimensionInputRules || [],
        absenceRequest
    );

    const formSetup = useFormWithApiIntegration<PostAbsenceRequestPayload>(
        formTarget,
        schemaData,
        defaultValues,
        {
            onSuccess,
            transformValidatedDataBeforeSend: adjustSubmittedDataToAbsenceLength
        }
    );

    const changedIsShortTermAbsence = formSetup.watch('isShortTermAbsence');
    useEffect(() => {
        if (changedIsShortTermAbsence !== isShortTermAbsence) {
            setIsShortTermAbsence(changedIsShortTermAbsence);
        }
    }, [changedIsShortTermAbsence, isShortTermAbsence, setIsShortTermAbsence]);

    return { ...formSetup, defaultValues };
}
